import { default as _91_46_46_46all_93KCdvJrelx3Meta } from "/home/runner/work/pos-fe/pos-fe/pages/[...all].vue?macro=true";
import { default as editwdfoRGRvUmMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/[attendance_id]/edit.vue?macro=true";
import { default as indexg0IAa3e8kMMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/[attendance_id]/index.vue?macro=true";
import { default as create5nyPYaRjc3Meta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/create.vue?macro=true";
import { default as indexnH9YTdsA8kMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/index.vue?macro=true";
import { default as edith2ksQoTCGuMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/companies/[company_id]/edit.vue?macro=true";
import { default as indexFkPCaAJ1Q5Meta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/companies/[company_id]/index.vue?macro=true";
import { default as createsEqDVgWJnVMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/companies/create.vue?macro=true";
import { default as indexIBag6fY34aMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/companies/index.vue?macro=true";
import { default as index4yXVaNdo8WMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/dashboard/index.vue?macro=true";
import { default as edit2nT8XXkEQlMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/employees/[employee_id]/edit.vue?macro=true";
import { default as indexSsm1PR2DHTMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/employees/[employee_id]/index.vue?macro=true";
import { default as createg15WWsQZASMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/employees/create.vue?macro=true";
import { default as createM8QHxgofOHMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/employees/forms/create.vue?macro=true";
import { default as indexQ41HnRxsn3Meta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/employees/index.vue?macro=true";
import { default as editeZEe2tAI0LMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/permission/[permission_id]/edit.vue?macro=true";
import { default as indexXLVr0Vwe5zMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/permission/[permission_id]/index.vue?macro=true";
import { default as createm9kpjoWF3ZMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/permission/create.vue?macro=true";
import { default as index1pC6DEv2gXMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/permission/index.vue?macro=true";
import { default as editFPBJf5l21iMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/roles/[role_id]/edit.vue?macro=true";
import { default as indexiTFQu1gye9Meta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/roles/[role_id]/index.vue?macro=true";
import { default as createkinC7RBtXlMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/roles/create.vue?macro=true";
import { default as indexOJvssv9xrjMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/roles/index.vue?macro=true";
import { default as editqIx1VBuvJmMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/[salary_id]/edit.vue?macro=true";
import { default as indexCQr3OlnmEYMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/[salary_id]/index.vue?macro=true";
import { default as create8vT9jCgp2IMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/create.vue?macro=true";
import { default as createg1ztMtsn2QMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/forms/create.vue?macro=true";
import { default as indextNpGbzzkgEMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/index.vue?macro=true";
import { default as createopwoCTMcK6Meta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/sales/create.vue?macro=true";
import { default as indexEZDNue8Vd6Meta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/sales/index.vue?macro=true";
import { default as edit5RqIrQaVDEMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/[shift_id]/edit.vue?macro=true";
import { default as indexp7BJcSJiiJMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/[shift_id]/index.vue?macro=true";
import { default as createn9gdNV0VRXMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/create.vue?macro=true";
import { default as createW7p8XgZD8YMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/forms/create.vue?macro=true";
import { default as indexwdlDNIaArWMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/index.vue?macro=true";
import { default as editcAhRXgz6TxMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/stores/[store_id]/edit.vue?macro=true";
import { default as index5OKxjNnP7vMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/stores/[store_id]/index.vue?macro=true";
import { default as createwNPdgIUWKxMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/stores/create.vue?macro=true";
import { default as createhga1aMsi5AMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/stores/forms/create.vue?macro=true";
import { default as indexZyuJSydE9SMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/stores/index.vue?macro=true";
import { default as editu3u0SjSydsMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/users/[user_id]/edit.vue?macro=true";
import { default as indexTfmtT7fvYqMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/users/[user_id]/index.vue?macro=true";
import { default as createJ0CqlTDIHTMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/users/create.vue?macro=true";
import { default as createINjQLuB8JQMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/users/forms/create.vue?macro=true";
import { default as indexvwu5WE4MbMMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/users/index.vue?macro=true";
import { default as editLPJv2Mlx3CMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/[warehouse_id]/edit.vue?macro=true";
import { default as indexw69C1lKyneMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/[warehouse_id]/index.vue?macro=true";
import { default as createJBNKmp6gCpMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/create.vue?macro=true";
import { default as createeCwGkmw29uMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/forms/create.vue?macro=true";
import { default as indexm5DwbOJ2XLMeta } from "/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/index.vue?macro=true";
import { default as indexeVB3XktwHsMeta } from "/home/runner/work/pos-fe/pos-fe/pages/index.vue?macro=true";
import { default as loginhxG9laGrjtMeta } from "/home/runner/work/pos-fe/pos-fe/pages/login.vue?macro=true";
import { default as editdHJPIMEw5XMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/[account_id]/edit.vue?macro=true";
import { default as indexbkasBhivCKMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/[account_id]/index.vue?macro=true";
import { default as create2eNXXwiaQTMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/create.vue?macro=true";
import { default as indexUJbBq9pbVyMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/index.vue?macro=true";
import { default as editCvrmRQjr1tMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/edit.vue?macro=true";
import { default as indexZRgyDjNgK6Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/index.vue?macro=true";
import { default as creategvUzqfKzMsMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/create.vue?macro=true";
import { default as indexzl4NY0ArswMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/index.vue?macro=true";
import { default as editf1EwleiDiPMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/[asset_depreciation_id]/edit.vue?macro=true";
import { default as indexQYJe8kXZc4Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/[asset_depreciation_id]/index.vue?macro=true";
import { default as create7OvtrsRcO5Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/create.vue?macro=true";
import { default as index49Lqa1LVKtMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/index.vue?macro=true";
import { default as edit93YnoMI8KEMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/[asset_type_id]/edit.vue?macro=true";
import { default as indexK7mKmtSjrNMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/[asset_type_id]/index.vue?macro=true";
import { default as createyBkLP4wZfmMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/create.vue?macro=true";
import { default as indexKEKbGjumEtMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/index.vue?macro=true";
import { default as editBPmP9KYiYIMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/[asset_id]/edit.vue?macro=true";
import { default as indexDZZEzSrqCEMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/[asset_id]/index.vue?macro=true";
import { default as createeapC64k5laMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/create.vue?macro=true";
import { default as indexYNRBDcC7LAMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/index.vue?macro=true";
import { default as edit1JLN6gB7IrMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/edit.vue?macro=true";
import { default as indexqb0Nru7IsvMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/index.vue?macro=true";
import { default as editZt44JMPNSBMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/[sub_category_id]/edit.vue?macro=true";
import { default as indexmEVHlqdK9wMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/[sub_category_id]/index.vue?macro=true";
import { default as createNIASO3W826Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/create.vue?macro=true";
import { default as indexrJXfD4DaoUMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/index.vue?macro=true";
import { default as createMnpIzeANYmMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/create.vue?macro=true";
import { default as indexJfvs1yczprMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/index.vue?macro=true";
import { default as editXC7NdhG0FhMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/[customer_id]/edit.vue?macro=true";
import { default as indexx34x17xSfCMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/[customer_id]/index.vue?macro=true";
import { default as createqTp1CDkbzNMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/create.vue?macro=true";
import { default as index88Tpttefd8Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/index.vue?macro=true";
import { default as indexoeb0zYGovaMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/dashboard/index.vue?macro=true";
import { default as indexkRxuX3gNAEMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/deposits/[deposit_id]/index.vue?macro=true";
import { default as createCy96CosBznMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/deposits/create.vue?macro=true";
import { default as index7Y7Bf6nghjMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/deposits/index.vue?macro=true";
import { default as indextgaPavHm4FMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expedition-transactions/[expedition_transaction_id]/index.vue?macro=true";
import { default as indexiG9ELFJHsSMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expedition-transactions/index.vue?macro=true";
import { default as editqRX06wlhYcMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/[expedition_id]/edit.vue?macro=true";
import { default as indexpK45ytmFWBMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/[expedition_id]/index.vue?macro=true";
import { default as createfFeik7OJIIMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/create.vue?macro=true";
import { default as indexs4JPnAYEJvMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/index.vue?macro=true";
import { default as index8HhMxt0hZGMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expenses/[expense_id]/index.vue?macro=true";
import { default as createYteDWudw0WMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expenses/create.vue?macro=true";
import { default as indexZ1ALKXvoQkMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expenses/index.vue?macro=true";
import { default as editaakw4Lt28xMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/edit.vue?macro=true";
import { default as editLSdgHLGZcUMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/[general_journal_entry_id]/edit.vue?macro=true";
import { default as indexKG2WcntYtyMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/[general_journal_entry_id]/index.vue?macro=true";
import { default as createXUmUqyXFFqMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/create.vue?macro=true";
import { default as indexBNTkyPE7rkMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/index.vue?macro=true";
import { default as indexk5k1lmYXUTMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/index.vue?macro=true";
import { default as createCY9ervPg5jMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/create.vue?macro=true";
import { default as index5qKWU2pv7YMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/index.vue?macro=true";
import { default as editbhBqmhbz3DMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/edit.vue?macro=true";
import { default as indexEhTvd7D7xoMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/index.vue?macro=true";
import { default as editW8pUou6Vi5Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/sell-prices/[sell_price_id]/edit.vue?macro=true";
import { default as indexCBc0rKZwZqMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/sell-prices/[sell_price_id]/index.vue?macro=true";
import { default as createDClvDmY7GIMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/sell-prices/create.vue?macro=true";
import { default as create6mnty6iyfCMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/create.vue?macro=true";
import { default as editImpeCjkHDsMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/edit.vue?macro=true";
import { default as indexOlfqh6C2URMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/index.vue?macro=true";
import { default as editx8cXggViwGMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/units/[unit_id]/edit.vue?macro=true";
import { default as indexezoe0qWHL6Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/units/[unit_id]/index.vue?macro=true";
import { default as createJpKnsLuwoeMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/units/create.vue?macro=true";
import { default as creategTe8U2gMR4Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/create.vue?macro=true";
import { default as createp3bIoXa1nZMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/forms/create.vue?macro=true";
import { default as indexogrFwB602NMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/index.vue?macro=true";
import { default as editiGHPlzXeubMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/[price_category_id]/edit.vue?macro=true";
import { default as indexyqyU9zcgiLMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/[price_category_id]/index.vue?macro=true";
import { default as createpbvmlR4HsjMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/create.vue?macro=true";
import { default as indexQIUSkF80nxMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/index.vue?macro=true";
import { default as index0frHZ9Wis6Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-checks/index.vue?macro=true";
import { default as editBqp62dIadnMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/[print_barcodes_id]/edit.vue?macro=true";
import { default as indexubIhozJMHuMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/[print_barcodes_id]/index.vue?macro=true";
import { default as createwPHTEjj2lyMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/create.vue?macro=true";
import { default as indexMcdpl5dwHJMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/index.vue?macro=true";
import { default as editJvRF5n9wYiMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/[printer_settings_id]/edit.vue?macro=true";
import { default as indexQCcEH1OZ8qMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/[printer_settings_id]/index.vue?macro=true";
import { default as createISONEjxoKwMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/create.vue?macro=true";
import { default as indexCYyWl4Vj4NMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/index.vue?macro=true";
import { default as profilepFva3LaRXQMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/profile.vue?macro=true";
import { default as editqLjwIivy37Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/edit.vue?macro=true";
import { default as indexkLJLcnjRjgMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/index.vue?macro=true";
import { default as editqOQbBldc2rMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/returns/[purchase_return_id]/edit.vue?macro=true";
import { default as indexs1Chtj7MneMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/returns/[purchase_return_id]/index.vue?macro=true";
import { default as createTYmvqD4UpqMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/returns/create.vue?macro=true";
import { default as createhI8C4HWDiFMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/create.vue?macro=true";
import { default as indexOZYmxrHHX3Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/index.vue?macro=true";
import { default as indexx2LnfLfhRjMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-returns/index.vue?macro=true";
import { default as editLRQNKjwccOMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/edit.vue?macro=true";
import { default as indexuUyl09SO9iMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/index.vue?macro=true";
import { default as editalxpEPnnoJMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/returns/[purchase_return_id]/edit.vue?macro=true";
import { default as indexZv5uVRmps6Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/returns/[purchase_return_id]/index.vue?macro=true";
import { default as createmTboSJ1BcWMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/returns/create.vue?macro=true";
import { default as createw5TzFAkl9vMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/create.vue?macro=true";
import { default as indexHk2MnVli04Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/index.vue?macro=true";
import { default as purchasesXP9IgsCKwoMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/reports/purchases.vue?macro=true";
import { default as salesCq7zUyguwGMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/reports/sales.vue?macro=true";
import { default as stock_45alerts8GTW9S0ZPXMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/reports/stock-alerts.vue?macro=true";
import { default as index0idVGL8DkeMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sale-returns/index.vue?macro=true";
import { default as edit0q46iJU0x7Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/edit.vue?macro=true";
import { default as indexhVUL7G2m13Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/index.vue?macro=true";
import { default as editCVQzmT69IqMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/returns/[sale_return_id]/edit.vue?macro=true";
import { default as indexy8Zlv4GlZXMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/returns/[sale_return_id]/index.vue?macro=true";
import { default as createn4nHR22mxBMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/returns/create.vue?macro=true";
import { default as create68Ym12Bia2Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/create.vue?macro=true";
import { default as index7wyMeuiVoHMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/index.vue?macro=true";
import { default as editL42ZmBECDjMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/[salesperson_id]/edit.vue?macro=true";
import { default as indexAm9sjjowvyMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/[salesperson_id]/index.vue?macro=true";
import { default as create1Juh5JiSemMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/create.vue?macro=true";
import { default as indexOwbSgwLl1XMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/index.vue?macro=true";
import { default as editdHRfrqVcWoMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/[service_type_id]/edit.vue?macro=true";
import { default as indexy0QIZwAsGSMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/[service_type_id]/index.vue?macro=true";
import { default as create8CF4pLevE0Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/create.vue?macro=true";
import { default as index8PbEwxmXknMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/index.vue?macro=true";
import { default as showcasePHdBfK5rEgMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/showcase.vue?macro=true";
import { default as editcwlV6eeQGGMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/[supplier_id]/edit.vue?macro=true";
import { default as index3Q0QPHC6xIMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/[supplier_id]/index.vue?macro=true";
import { default as createjzDPbqgtkWMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/create.vue?macro=true";
import { default as indexfMUMDsxiN4Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/index.vue?macro=true";
import { default as editd7NMyrhFhPMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/[unit_id]/edit.vue?macro=true";
import { default as indexlLmtwQh3u1Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/[unit_id]/index.vue?macro=true";
import { default as createziCwl6kH40Meta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/create.vue?macro=true";
import { default as indexdg4MrA6cjFMeta } from "/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/index.vue?macro=true";
import { default as index1mi44Cd7YfMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/admin-receivings/[admin_receiving_id]/index.vue?macro=true";
import { default as indexJrCxZgoHVOMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/admin-receivings/index.vue?macro=true";
import { default as indexOM7H5xXufFMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/dashboard/index.vue?macro=true";
import { default as index8J05EUVxvXMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/item-deliveries/[item_delivery_id]/index.vue?macro=true";
import { default as indexX2mOGtp8FsMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/item-deliveries/index.vue?macro=true";
import { default as indexgSpz6CUsZHMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-adjustments/[stock_adjustment_id]/index.vue?macro=true";
import { default as createaDW3vfr2dxMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-adjustments/create.vue?macro=true";
import { default as indexhO0bQnLUmBMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-adjustments/index.vue?macro=true";
import { default as indexyuuAk071WcMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-transfers/[stock_transfer_id]/index.vue?macro=true";
import { default as createa8gqkuyMq3Meta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-transfers/create.vue?macro=true";
import { default as indexWmdeJjxu9zMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-transfers/index.vue?macro=true";
import { default as editTBvOEhhs8fMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/[warehouse_receiving_id]/edit.vue?macro=true";
import { default as indexY7Vj49Rr5HMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/[warehouse_receiving_id]/index.vue?macro=true";
import { default as createEyik0Z0wEWMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/create.vue?macro=true";
import { default as indexhAGNclQfwtMeta } from "/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93KCdvJrelx3Meta?.name ?? "all___id",
    path: _91_46_46_46all_93KCdvJrelx3Meta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93KCdvJrelx3Meta || {},
    alias: _91_46_46_46all_93KCdvJrelx3Meta?.alias || [],
    redirect: _91_46_46_46all_93KCdvJrelx3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93KCdvJrelx3Meta?.name ?? "all___en",
    path: _91_46_46_46all_93KCdvJrelx3Meta?.path ?? "/en/:all(.*)*",
    meta: _91_46_46_46all_93KCdvJrelx3Meta || {},
    alias: _91_46_46_46all_93KCdvJrelx3Meta?.alias || [],
    redirect: _91_46_46_46all_93KCdvJrelx3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: editwdfoRGRvUmMeta?.name ?? "companies-attendances-attendance_id-edit___id",
    path: editwdfoRGRvUmMeta?.path ?? "/companies/attendances/:attendance_id()/edit",
    meta: editwdfoRGRvUmMeta || {},
    alias: editwdfoRGRvUmMeta?.alias || [],
    redirect: editwdfoRGRvUmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/[attendance_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editwdfoRGRvUmMeta?.name ?? "companies-attendances-attendance_id-edit___en",
    path: editwdfoRGRvUmMeta?.path ?? "/en/companies/attendances/:attendance_id()/edit",
    meta: editwdfoRGRvUmMeta || {},
    alias: editwdfoRGRvUmMeta?.alias || [],
    redirect: editwdfoRGRvUmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/[attendance_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexg0IAa3e8kMMeta?.name ?? "companies-attendances-attendance_id___id",
    path: indexg0IAa3e8kMMeta?.path ?? "/companies/attendances/:attendance_id()",
    meta: indexg0IAa3e8kMMeta || {},
    alias: indexg0IAa3e8kMMeta?.alias || [],
    redirect: indexg0IAa3e8kMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/[attendance_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0IAa3e8kMMeta?.name ?? "companies-attendances-attendance_id___en",
    path: indexg0IAa3e8kMMeta?.path ?? "/en/companies/attendances/:attendance_id()",
    meta: indexg0IAa3e8kMMeta || {},
    alias: indexg0IAa3e8kMMeta?.alias || [],
    redirect: indexg0IAa3e8kMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/[attendance_id]/index.vue").then(m => m.default || m)
  },
  {
    name: create5nyPYaRjc3Meta?.name ?? "companies-attendances-create___id",
    path: create5nyPYaRjc3Meta?.path ?? "/companies/attendances/create",
    meta: create5nyPYaRjc3Meta || {},
    alias: create5nyPYaRjc3Meta?.alias || [],
    redirect: create5nyPYaRjc3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/create.vue").then(m => m.default || m)
  },
  {
    name: create5nyPYaRjc3Meta?.name ?? "companies-attendances-create___en",
    path: create5nyPYaRjc3Meta?.path ?? "/en/companies/attendances/create",
    meta: create5nyPYaRjc3Meta || {},
    alias: create5nyPYaRjc3Meta?.alias || [],
    redirect: create5nyPYaRjc3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/create.vue").then(m => m.default || m)
  },
  {
    name: indexnH9YTdsA8kMeta?.name ?? "companies-attendances___id",
    path: indexnH9YTdsA8kMeta?.path ?? "/companies/attendances",
    meta: indexnH9YTdsA8kMeta || {},
    alias: indexnH9YTdsA8kMeta?.alias || [],
    redirect: indexnH9YTdsA8kMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/index.vue").then(m => m.default || m)
  },
  {
    name: indexnH9YTdsA8kMeta?.name ?? "companies-attendances___en",
    path: indexnH9YTdsA8kMeta?.path ?? "/en/companies/attendances",
    meta: indexnH9YTdsA8kMeta || {},
    alias: indexnH9YTdsA8kMeta?.alias || [],
    redirect: indexnH9YTdsA8kMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/attendances/index.vue").then(m => m.default || m)
  },
  {
    name: edith2ksQoTCGuMeta?.name ?? "companies-companies-company_id-edit___id",
    path: edith2ksQoTCGuMeta?.path ?? "/companies/companies/:company_id()/edit",
    meta: edith2ksQoTCGuMeta || {},
    alias: edith2ksQoTCGuMeta?.alias || [],
    redirect: edith2ksQoTCGuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/companies/[company_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: edith2ksQoTCGuMeta?.name ?? "companies-companies-company_id-edit___en",
    path: edith2ksQoTCGuMeta?.path ?? "/en/companies/companies/:company_id()/edit",
    meta: edith2ksQoTCGuMeta || {},
    alias: edith2ksQoTCGuMeta?.alias || [],
    redirect: edith2ksQoTCGuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/companies/[company_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexFkPCaAJ1Q5Meta?.name ?? "companies-companies-company_id___id",
    path: indexFkPCaAJ1Q5Meta?.path ?? "/companies/companies/:company_id()",
    meta: indexFkPCaAJ1Q5Meta || {},
    alias: indexFkPCaAJ1Q5Meta?.alias || [],
    redirect: indexFkPCaAJ1Q5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/companies/[company_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFkPCaAJ1Q5Meta?.name ?? "companies-companies-company_id___en",
    path: indexFkPCaAJ1Q5Meta?.path ?? "/en/companies/companies/:company_id()",
    meta: indexFkPCaAJ1Q5Meta || {},
    alias: indexFkPCaAJ1Q5Meta?.alias || [],
    redirect: indexFkPCaAJ1Q5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/companies/[company_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createsEqDVgWJnVMeta?.name ?? "companies-companies-create___id",
    path: createsEqDVgWJnVMeta?.path ?? "/companies/companies/create",
    meta: createsEqDVgWJnVMeta || {},
    alias: createsEqDVgWJnVMeta?.alias || [],
    redirect: createsEqDVgWJnVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/companies/create.vue").then(m => m.default || m)
  },
  {
    name: createsEqDVgWJnVMeta?.name ?? "companies-companies-create___en",
    path: createsEqDVgWJnVMeta?.path ?? "/en/companies/companies/create",
    meta: createsEqDVgWJnVMeta || {},
    alias: createsEqDVgWJnVMeta?.alias || [],
    redirect: createsEqDVgWJnVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/companies/create.vue").then(m => m.default || m)
  },
  {
    name: indexIBag6fY34aMeta?.name ?? "companies-companies___id",
    path: indexIBag6fY34aMeta?.path ?? "/companies/companies",
    meta: indexIBag6fY34aMeta || {},
    alias: indexIBag6fY34aMeta?.alias || [],
    redirect: indexIBag6fY34aMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexIBag6fY34aMeta?.name ?? "companies-companies___en",
    path: indexIBag6fY34aMeta?.path ?? "/en/companies/companies",
    meta: indexIBag6fY34aMeta || {},
    alias: indexIBag6fY34aMeta?.alias || [],
    redirect: indexIBag6fY34aMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/companies/index.vue").then(m => m.default || m)
  },
  {
    name: index4yXVaNdo8WMeta?.name ?? "companies-dashboard___id",
    path: index4yXVaNdo8WMeta?.path ?? "/companies/dashboard",
    meta: index4yXVaNdo8WMeta || {},
    alias: index4yXVaNdo8WMeta?.alias || [],
    redirect: index4yXVaNdo8WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: index4yXVaNdo8WMeta?.name ?? "companies-dashboard___en",
    path: index4yXVaNdo8WMeta?.path ?? "/en/companies/dashboard",
    meta: index4yXVaNdo8WMeta || {},
    alias: index4yXVaNdo8WMeta?.alias || [],
    redirect: index4yXVaNdo8WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: edit2nT8XXkEQlMeta?.name ?? "companies-employees-employee_id-edit___id",
    path: edit2nT8XXkEQlMeta?.path ?? "/companies/employees/:employee_id()/edit",
    meta: edit2nT8XXkEQlMeta || {},
    alias: edit2nT8XXkEQlMeta?.alias || [],
    redirect: edit2nT8XXkEQlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/employees/[employee_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit2nT8XXkEQlMeta?.name ?? "companies-employees-employee_id-edit___en",
    path: edit2nT8XXkEQlMeta?.path ?? "/en/companies/employees/:employee_id()/edit",
    meta: edit2nT8XXkEQlMeta || {},
    alias: edit2nT8XXkEQlMeta?.alias || [],
    redirect: edit2nT8XXkEQlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/employees/[employee_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexSsm1PR2DHTMeta?.name ?? "companies-employees-employee_id___id",
    path: indexSsm1PR2DHTMeta?.path ?? "/companies/employees/:employee_id()",
    meta: indexSsm1PR2DHTMeta || {},
    alias: indexSsm1PR2DHTMeta?.alias || [],
    redirect: indexSsm1PR2DHTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/employees/[employee_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSsm1PR2DHTMeta?.name ?? "companies-employees-employee_id___en",
    path: indexSsm1PR2DHTMeta?.path ?? "/en/companies/employees/:employee_id()",
    meta: indexSsm1PR2DHTMeta || {},
    alias: indexSsm1PR2DHTMeta?.alias || [],
    redirect: indexSsm1PR2DHTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/employees/[employee_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createg15WWsQZASMeta?.name ?? "companies-employees-create___id",
    path: createg15WWsQZASMeta?.path ?? "/companies/employees/create",
    meta: createg15WWsQZASMeta || {},
    alias: createg15WWsQZASMeta?.alias || [],
    redirect: createg15WWsQZASMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/employees/create.vue").then(m => m.default || m)
  },
  {
    name: createg15WWsQZASMeta?.name ?? "companies-employees-create___en",
    path: createg15WWsQZASMeta?.path ?? "/en/companies/employees/create",
    meta: createg15WWsQZASMeta || {},
    alias: createg15WWsQZASMeta?.alias || [],
    redirect: createg15WWsQZASMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/employees/create.vue").then(m => m.default || m)
  },
  {
    name: createM8QHxgofOHMeta?.name ?? "companies-employees-forms-create___id",
    path: createM8QHxgofOHMeta?.path ?? "/companies/employees/forms/create",
    meta: createM8QHxgofOHMeta || {},
    alias: createM8QHxgofOHMeta?.alias || [],
    redirect: createM8QHxgofOHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/employees/forms/create.vue").then(m => m.default || m)
  },
  {
    name: createM8QHxgofOHMeta?.name ?? "companies-employees-forms-create___en",
    path: createM8QHxgofOHMeta?.path ?? "/en/companies/employees/forms/create",
    meta: createM8QHxgofOHMeta || {},
    alias: createM8QHxgofOHMeta?.alias || [],
    redirect: createM8QHxgofOHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/employees/forms/create.vue").then(m => m.default || m)
  },
  {
    name: indexQ41HnRxsn3Meta?.name ?? "companies-employees___id",
    path: indexQ41HnRxsn3Meta?.path ?? "/companies/employees",
    meta: indexQ41HnRxsn3Meta || {},
    alias: indexQ41HnRxsn3Meta?.alias || [],
    redirect: indexQ41HnRxsn3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/employees/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ41HnRxsn3Meta?.name ?? "companies-employees___en",
    path: indexQ41HnRxsn3Meta?.path ?? "/en/companies/employees",
    meta: indexQ41HnRxsn3Meta || {},
    alias: indexQ41HnRxsn3Meta?.alias || [],
    redirect: indexQ41HnRxsn3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/employees/index.vue").then(m => m.default || m)
  },
  {
    name: editeZEe2tAI0LMeta?.name ?? "companies-permission-permission_id-edit___id",
    path: editeZEe2tAI0LMeta?.path ?? "/companies/permission/:permission_id()/edit",
    meta: editeZEe2tAI0LMeta || {},
    alias: editeZEe2tAI0LMeta?.alias || [],
    redirect: editeZEe2tAI0LMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/permission/[permission_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editeZEe2tAI0LMeta?.name ?? "companies-permission-permission_id-edit___en",
    path: editeZEe2tAI0LMeta?.path ?? "/en/companies/permission/:permission_id()/edit",
    meta: editeZEe2tAI0LMeta || {},
    alias: editeZEe2tAI0LMeta?.alias || [],
    redirect: editeZEe2tAI0LMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/permission/[permission_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexXLVr0Vwe5zMeta?.name ?? "companies-permission-permission_id___id",
    path: indexXLVr0Vwe5zMeta?.path ?? "/companies/permission/:permission_id()",
    meta: indexXLVr0Vwe5zMeta || {},
    alias: indexXLVr0Vwe5zMeta?.alias || [],
    redirect: indexXLVr0Vwe5zMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/permission/[permission_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXLVr0Vwe5zMeta?.name ?? "companies-permission-permission_id___en",
    path: indexXLVr0Vwe5zMeta?.path ?? "/en/companies/permission/:permission_id()",
    meta: indexXLVr0Vwe5zMeta || {},
    alias: indexXLVr0Vwe5zMeta?.alias || [],
    redirect: indexXLVr0Vwe5zMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/permission/[permission_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createm9kpjoWF3ZMeta?.name ?? "companies-permission-create___id",
    path: createm9kpjoWF3ZMeta?.path ?? "/companies/permission/create",
    meta: createm9kpjoWF3ZMeta || {},
    alias: createm9kpjoWF3ZMeta?.alias || [],
    redirect: createm9kpjoWF3ZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/permission/create.vue").then(m => m.default || m)
  },
  {
    name: createm9kpjoWF3ZMeta?.name ?? "companies-permission-create___en",
    path: createm9kpjoWF3ZMeta?.path ?? "/en/companies/permission/create",
    meta: createm9kpjoWF3ZMeta || {},
    alias: createm9kpjoWF3ZMeta?.alias || [],
    redirect: createm9kpjoWF3ZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/permission/create.vue").then(m => m.default || m)
  },
  {
    name: index1pC6DEv2gXMeta?.name ?? "companies-permission___id",
    path: index1pC6DEv2gXMeta?.path ?? "/companies/permission",
    meta: index1pC6DEv2gXMeta || {},
    alias: index1pC6DEv2gXMeta?.alias || [],
    redirect: index1pC6DEv2gXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/permission/index.vue").then(m => m.default || m)
  },
  {
    name: index1pC6DEv2gXMeta?.name ?? "companies-permission___en",
    path: index1pC6DEv2gXMeta?.path ?? "/en/companies/permission",
    meta: index1pC6DEv2gXMeta || {},
    alias: index1pC6DEv2gXMeta?.alias || [],
    redirect: index1pC6DEv2gXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/permission/index.vue").then(m => m.default || m)
  },
  {
    name: editFPBJf5l21iMeta?.name ?? "companies-roles-role_id-edit___id",
    path: editFPBJf5l21iMeta?.path ?? "/companies/roles/:role_id()/edit",
    meta: editFPBJf5l21iMeta || {},
    alias: editFPBJf5l21iMeta?.alias || [],
    redirect: editFPBJf5l21iMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/roles/[role_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editFPBJf5l21iMeta?.name ?? "companies-roles-role_id-edit___en",
    path: editFPBJf5l21iMeta?.path ?? "/en/companies/roles/:role_id()/edit",
    meta: editFPBJf5l21iMeta || {},
    alias: editFPBJf5l21iMeta?.alias || [],
    redirect: editFPBJf5l21iMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/roles/[role_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexiTFQu1gye9Meta?.name ?? "companies-roles-role_id___id",
    path: indexiTFQu1gye9Meta?.path ?? "/companies/roles/:role_id()",
    meta: indexiTFQu1gye9Meta || {},
    alias: indexiTFQu1gye9Meta?.alias || [],
    redirect: indexiTFQu1gye9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/roles/[role_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiTFQu1gye9Meta?.name ?? "companies-roles-role_id___en",
    path: indexiTFQu1gye9Meta?.path ?? "/en/companies/roles/:role_id()",
    meta: indexiTFQu1gye9Meta || {},
    alias: indexiTFQu1gye9Meta?.alias || [],
    redirect: indexiTFQu1gye9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/roles/[role_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createkinC7RBtXlMeta?.name ?? "companies-roles-create___id",
    path: createkinC7RBtXlMeta?.path ?? "/companies/roles/create",
    meta: createkinC7RBtXlMeta || {},
    alias: createkinC7RBtXlMeta?.alias || [],
    redirect: createkinC7RBtXlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/roles/create.vue").then(m => m.default || m)
  },
  {
    name: createkinC7RBtXlMeta?.name ?? "companies-roles-create___en",
    path: createkinC7RBtXlMeta?.path ?? "/en/companies/roles/create",
    meta: createkinC7RBtXlMeta || {},
    alias: createkinC7RBtXlMeta?.alias || [],
    redirect: createkinC7RBtXlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/roles/create.vue").then(m => m.default || m)
  },
  {
    name: indexOJvssv9xrjMeta?.name ?? "companies-roles___id",
    path: indexOJvssv9xrjMeta?.path ?? "/companies/roles",
    meta: indexOJvssv9xrjMeta || {},
    alias: indexOJvssv9xrjMeta?.alias || [],
    redirect: indexOJvssv9xrjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexOJvssv9xrjMeta?.name ?? "companies-roles___en",
    path: indexOJvssv9xrjMeta?.path ?? "/en/companies/roles",
    meta: indexOJvssv9xrjMeta || {},
    alias: indexOJvssv9xrjMeta?.alias || [],
    redirect: indexOJvssv9xrjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/roles/index.vue").then(m => m.default || m)
  },
  {
    name: editqIx1VBuvJmMeta?.name ?? "companies-salaries-salary_id-edit___id",
    path: editqIx1VBuvJmMeta?.path ?? "/companies/salaries/:salary_id()/edit",
    meta: editqIx1VBuvJmMeta || {},
    alias: editqIx1VBuvJmMeta?.alias || [],
    redirect: editqIx1VBuvJmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/[salary_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editqIx1VBuvJmMeta?.name ?? "companies-salaries-salary_id-edit___en",
    path: editqIx1VBuvJmMeta?.path ?? "/en/companies/salaries/:salary_id()/edit",
    meta: editqIx1VBuvJmMeta || {},
    alias: editqIx1VBuvJmMeta?.alias || [],
    redirect: editqIx1VBuvJmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/[salary_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexCQr3OlnmEYMeta?.name ?? "companies-salaries-salary_id___id",
    path: indexCQr3OlnmEYMeta?.path ?? "/companies/salaries/:salary_id()",
    meta: indexCQr3OlnmEYMeta || {},
    alias: indexCQr3OlnmEYMeta?.alias || [],
    redirect: indexCQr3OlnmEYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/[salary_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexCQr3OlnmEYMeta?.name ?? "companies-salaries-salary_id___en",
    path: indexCQr3OlnmEYMeta?.path ?? "/en/companies/salaries/:salary_id()",
    meta: indexCQr3OlnmEYMeta || {},
    alias: indexCQr3OlnmEYMeta?.alias || [],
    redirect: indexCQr3OlnmEYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/[salary_id]/index.vue").then(m => m.default || m)
  },
  {
    name: create8vT9jCgp2IMeta?.name ?? "companies-salaries-create___id",
    path: create8vT9jCgp2IMeta?.path ?? "/companies/salaries/create",
    meta: create8vT9jCgp2IMeta || {},
    alias: create8vT9jCgp2IMeta?.alias || [],
    redirect: create8vT9jCgp2IMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/create.vue").then(m => m.default || m)
  },
  {
    name: create8vT9jCgp2IMeta?.name ?? "companies-salaries-create___en",
    path: create8vT9jCgp2IMeta?.path ?? "/en/companies/salaries/create",
    meta: create8vT9jCgp2IMeta || {},
    alias: create8vT9jCgp2IMeta?.alias || [],
    redirect: create8vT9jCgp2IMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/create.vue").then(m => m.default || m)
  },
  {
    name: createg1ztMtsn2QMeta?.name ?? "companies-salaries-forms-create___id",
    path: createg1ztMtsn2QMeta?.path ?? "/companies/salaries/forms/create",
    meta: createg1ztMtsn2QMeta || {},
    alias: createg1ztMtsn2QMeta?.alias || [],
    redirect: createg1ztMtsn2QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/forms/create.vue").then(m => m.default || m)
  },
  {
    name: createg1ztMtsn2QMeta?.name ?? "companies-salaries-forms-create___en",
    path: createg1ztMtsn2QMeta?.path ?? "/en/companies/salaries/forms/create",
    meta: createg1ztMtsn2QMeta || {},
    alias: createg1ztMtsn2QMeta?.alias || [],
    redirect: createg1ztMtsn2QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/forms/create.vue").then(m => m.default || m)
  },
  {
    name: indextNpGbzzkgEMeta?.name ?? "companies-salaries___id",
    path: indextNpGbzzkgEMeta?.path ?? "/companies/salaries",
    meta: indextNpGbzzkgEMeta || {},
    alias: indextNpGbzzkgEMeta?.alias || [],
    redirect: indextNpGbzzkgEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/index.vue").then(m => m.default || m)
  },
  {
    name: indextNpGbzzkgEMeta?.name ?? "companies-salaries___en",
    path: indextNpGbzzkgEMeta?.path ?? "/en/companies/salaries",
    meta: indextNpGbzzkgEMeta || {},
    alias: indextNpGbzzkgEMeta?.alias || [],
    redirect: indextNpGbzzkgEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/salaries/index.vue").then(m => m.default || m)
  },
  {
    name: createopwoCTMcK6Meta?.name ?? "companies-sales-create___id",
    path: createopwoCTMcK6Meta?.path ?? "/companies/sales/create",
    meta: createopwoCTMcK6Meta || {},
    alias: createopwoCTMcK6Meta?.alias || [],
    redirect: createopwoCTMcK6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/sales/create.vue").then(m => m.default || m)
  },
  {
    name: createopwoCTMcK6Meta?.name ?? "companies-sales-create___en",
    path: createopwoCTMcK6Meta?.path ?? "/en/companies/sales/create",
    meta: createopwoCTMcK6Meta || {},
    alias: createopwoCTMcK6Meta?.alias || [],
    redirect: createopwoCTMcK6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/sales/create.vue").then(m => m.default || m)
  },
  {
    name: indexEZDNue8Vd6Meta?.name ?? "companies-sales___id",
    path: indexEZDNue8Vd6Meta?.path ?? "/companies/sales",
    meta: indexEZDNue8Vd6Meta || {},
    alias: indexEZDNue8Vd6Meta?.alias || [],
    redirect: indexEZDNue8Vd6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/sales/index.vue").then(m => m.default || m)
  },
  {
    name: indexEZDNue8Vd6Meta?.name ?? "companies-sales___en",
    path: indexEZDNue8Vd6Meta?.path ?? "/en/companies/sales",
    meta: indexEZDNue8Vd6Meta || {},
    alias: indexEZDNue8Vd6Meta?.alias || [],
    redirect: indexEZDNue8Vd6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/sales/index.vue").then(m => m.default || m)
  },
  {
    name: edit5RqIrQaVDEMeta?.name ?? "companies-shifts-shift_id-edit___id",
    path: edit5RqIrQaVDEMeta?.path ?? "/companies/shifts/:shift_id()/edit",
    meta: edit5RqIrQaVDEMeta || {},
    alias: edit5RqIrQaVDEMeta?.alias || [],
    redirect: edit5RqIrQaVDEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/[shift_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit5RqIrQaVDEMeta?.name ?? "companies-shifts-shift_id-edit___en",
    path: edit5RqIrQaVDEMeta?.path ?? "/en/companies/shifts/:shift_id()/edit",
    meta: edit5RqIrQaVDEMeta || {},
    alias: edit5RqIrQaVDEMeta?.alias || [],
    redirect: edit5RqIrQaVDEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/[shift_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexp7BJcSJiiJMeta?.name ?? "companies-shifts-shift_id___id",
    path: indexp7BJcSJiiJMeta?.path ?? "/companies/shifts/:shift_id()",
    meta: indexp7BJcSJiiJMeta || {},
    alias: indexp7BJcSJiiJMeta?.alias || [],
    redirect: indexp7BJcSJiiJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/[shift_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexp7BJcSJiiJMeta?.name ?? "companies-shifts-shift_id___en",
    path: indexp7BJcSJiiJMeta?.path ?? "/en/companies/shifts/:shift_id()",
    meta: indexp7BJcSJiiJMeta || {},
    alias: indexp7BJcSJiiJMeta?.alias || [],
    redirect: indexp7BJcSJiiJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/[shift_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createn9gdNV0VRXMeta?.name ?? "companies-shifts-create___id",
    path: createn9gdNV0VRXMeta?.path ?? "/companies/shifts/create",
    meta: createn9gdNV0VRXMeta || {},
    alias: createn9gdNV0VRXMeta?.alias || [],
    redirect: createn9gdNV0VRXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/create.vue").then(m => m.default || m)
  },
  {
    name: createn9gdNV0VRXMeta?.name ?? "companies-shifts-create___en",
    path: createn9gdNV0VRXMeta?.path ?? "/en/companies/shifts/create",
    meta: createn9gdNV0VRXMeta || {},
    alias: createn9gdNV0VRXMeta?.alias || [],
    redirect: createn9gdNV0VRXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/create.vue").then(m => m.default || m)
  },
  {
    name: createW7p8XgZD8YMeta?.name ?? "companies-shifts-forms-create___id",
    path: createW7p8XgZD8YMeta?.path ?? "/companies/shifts/forms/create",
    meta: createW7p8XgZD8YMeta || {},
    alias: createW7p8XgZD8YMeta?.alias || [],
    redirect: createW7p8XgZD8YMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/forms/create.vue").then(m => m.default || m)
  },
  {
    name: createW7p8XgZD8YMeta?.name ?? "companies-shifts-forms-create___en",
    path: createW7p8XgZD8YMeta?.path ?? "/en/companies/shifts/forms/create",
    meta: createW7p8XgZD8YMeta || {},
    alias: createW7p8XgZD8YMeta?.alias || [],
    redirect: createW7p8XgZD8YMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/forms/create.vue").then(m => m.default || m)
  },
  {
    name: indexwdlDNIaArWMeta?.name ?? "companies-shifts___id",
    path: indexwdlDNIaArWMeta?.path ?? "/companies/shifts",
    meta: indexwdlDNIaArWMeta || {},
    alias: indexwdlDNIaArWMeta?.alias || [],
    redirect: indexwdlDNIaArWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/index.vue").then(m => m.default || m)
  },
  {
    name: indexwdlDNIaArWMeta?.name ?? "companies-shifts___en",
    path: indexwdlDNIaArWMeta?.path ?? "/en/companies/shifts",
    meta: indexwdlDNIaArWMeta || {},
    alias: indexwdlDNIaArWMeta?.alias || [],
    redirect: indexwdlDNIaArWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/shifts/index.vue").then(m => m.default || m)
  },
  {
    name: editcAhRXgz6TxMeta?.name ?? "companies-stores-store_id-edit___id",
    path: editcAhRXgz6TxMeta?.path ?? "/companies/stores/:store_id()/edit",
    meta: editcAhRXgz6TxMeta || {},
    alias: editcAhRXgz6TxMeta?.alias || [],
    redirect: editcAhRXgz6TxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/stores/[store_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editcAhRXgz6TxMeta?.name ?? "companies-stores-store_id-edit___en",
    path: editcAhRXgz6TxMeta?.path ?? "/en/companies/stores/:store_id()/edit",
    meta: editcAhRXgz6TxMeta || {},
    alias: editcAhRXgz6TxMeta?.alias || [],
    redirect: editcAhRXgz6TxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/stores/[store_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index5OKxjNnP7vMeta?.name ?? "companies-stores-store_id___id",
    path: index5OKxjNnP7vMeta?.path ?? "/companies/stores/:store_id()",
    meta: index5OKxjNnP7vMeta || {},
    alias: index5OKxjNnP7vMeta?.alias || [],
    redirect: index5OKxjNnP7vMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/stores/[store_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index5OKxjNnP7vMeta?.name ?? "companies-stores-store_id___en",
    path: index5OKxjNnP7vMeta?.path ?? "/en/companies/stores/:store_id()",
    meta: index5OKxjNnP7vMeta || {},
    alias: index5OKxjNnP7vMeta?.alias || [],
    redirect: index5OKxjNnP7vMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/stores/[store_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createwNPdgIUWKxMeta?.name ?? "companies-stores-create___id",
    path: createwNPdgIUWKxMeta?.path ?? "/companies/stores/create",
    meta: createwNPdgIUWKxMeta || {},
    alias: createwNPdgIUWKxMeta?.alias || [],
    redirect: createwNPdgIUWKxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/stores/create.vue").then(m => m.default || m)
  },
  {
    name: createwNPdgIUWKxMeta?.name ?? "companies-stores-create___en",
    path: createwNPdgIUWKxMeta?.path ?? "/en/companies/stores/create",
    meta: createwNPdgIUWKxMeta || {},
    alias: createwNPdgIUWKxMeta?.alias || [],
    redirect: createwNPdgIUWKxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/stores/create.vue").then(m => m.default || m)
  },
  {
    name: createhga1aMsi5AMeta?.name ?? "companies-stores-forms-create___id",
    path: createhga1aMsi5AMeta?.path ?? "/companies/stores/forms/create",
    meta: createhga1aMsi5AMeta || {},
    alias: createhga1aMsi5AMeta?.alias || [],
    redirect: createhga1aMsi5AMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/stores/forms/create.vue").then(m => m.default || m)
  },
  {
    name: createhga1aMsi5AMeta?.name ?? "companies-stores-forms-create___en",
    path: createhga1aMsi5AMeta?.path ?? "/en/companies/stores/forms/create",
    meta: createhga1aMsi5AMeta || {},
    alias: createhga1aMsi5AMeta?.alias || [],
    redirect: createhga1aMsi5AMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/stores/forms/create.vue").then(m => m.default || m)
  },
  {
    name: indexZyuJSydE9SMeta?.name ?? "companies-stores___id",
    path: indexZyuJSydE9SMeta?.path ?? "/companies/stores",
    meta: indexZyuJSydE9SMeta || {},
    alias: indexZyuJSydE9SMeta?.alias || [],
    redirect: indexZyuJSydE9SMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/stores/index.vue").then(m => m.default || m)
  },
  {
    name: indexZyuJSydE9SMeta?.name ?? "companies-stores___en",
    path: indexZyuJSydE9SMeta?.path ?? "/en/companies/stores",
    meta: indexZyuJSydE9SMeta || {},
    alias: indexZyuJSydE9SMeta?.alias || [],
    redirect: indexZyuJSydE9SMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/stores/index.vue").then(m => m.default || m)
  },
  {
    name: editu3u0SjSydsMeta?.name ?? "companies-users-user_id-edit___id",
    path: editu3u0SjSydsMeta?.path ?? "/companies/users/:user_id()/edit",
    meta: editu3u0SjSydsMeta || {},
    alias: editu3u0SjSydsMeta?.alias || [],
    redirect: editu3u0SjSydsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editu3u0SjSydsMeta?.name ?? "companies-users-user_id-edit___en",
    path: editu3u0SjSydsMeta?.path ?? "/en/companies/users/:user_id()/edit",
    meta: editu3u0SjSydsMeta || {},
    alias: editu3u0SjSydsMeta?.alias || [],
    redirect: editu3u0SjSydsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexTfmtT7fvYqMeta?.name ?? "companies-users-user_id___id",
    path: indexTfmtT7fvYqMeta?.path ?? "/companies/users/:user_id()",
    meta: indexTfmtT7fvYqMeta || {},
    alias: indexTfmtT7fvYqMeta?.alias || [],
    redirect: indexTfmtT7fvYqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/users/[user_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTfmtT7fvYqMeta?.name ?? "companies-users-user_id___en",
    path: indexTfmtT7fvYqMeta?.path ?? "/en/companies/users/:user_id()",
    meta: indexTfmtT7fvYqMeta || {},
    alias: indexTfmtT7fvYqMeta?.alias || [],
    redirect: indexTfmtT7fvYqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/users/[user_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createJ0CqlTDIHTMeta?.name ?? "companies-users-create___id",
    path: createJ0CqlTDIHTMeta?.path ?? "/companies/users/create",
    meta: createJ0CqlTDIHTMeta || {},
    alias: createJ0CqlTDIHTMeta?.alias || [],
    redirect: createJ0CqlTDIHTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/users/create.vue").then(m => m.default || m)
  },
  {
    name: createJ0CqlTDIHTMeta?.name ?? "companies-users-create___en",
    path: createJ0CqlTDIHTMeta?.path ?? "/en/companies/users/create",
    meta: createJ0CqlTDIHTMeta || {},
    alias: createJ0CqlTDIHTMeta?.alias || [],
    redirect: createJ0CqlTDIHTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/users/create.vue").then(m => m.default || m)
  },
  {
    name: createINjQLuB8JQMeta?.name ?? "companies-users-forms-create___id",
    path: createINjQLuB8JQMeta?.path ?? "/companies/users/forms/create",
    meta: createINjQLuB8JQMeta || {},
    alias: createINjQLuB8JQMeta?.alias || [],
    redirect: createINjQLuB8JQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/users/forms/create.vue").then(m => m.default || m)
  },
  {
    name: createINjQLuB8JQMeta?.name ?? "companies-users-forms-create___en",
    path: createINjQLuB8JQMeta?.path ?? "/en/companies/users/forms/create",
    meta: createINjQLuB8JQMeta || {},
    alias: createINjQLuB8JQMeta?.alias || [],
    redirect: createINjQLuB8JQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/users/forms/create.vue").then(m => m.default || m)
  },
  {
    name: indexvwu5WE4MbMMeta?.name ?? "companies-users___id",
    path: indexvwu5WE4MbMMeta?.path ?? "/companies/users",
    meta: indexvwu5WE4MbMMeta || {},
    alias: indexvwu5WE4MbMMeta?.alias || [],
    redirect: indexvwu5WE4MbMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexvwu5WE4MbMMeta?.name ?? "companies-users___en",
    path: indexvwu5WE4MbMMeta?.path ?? "/en/companies/users",
    meta: indexvwu5WE4MbMMeta || {},
    alias: indexvwu5WE4MbMMeta?.alias || [],
    redirect: indexvwu5WE4MbMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/users/index.vue").then(m => m.default || m)
  },
  {
    name: editLPJv2Mlx3CMeta?.name ?? "companies-warehouses-warehouse_id-edit___id",
    path: editLPJv2Mlx3CMeta?.path ?? "/companies/warehouses/:warehouse_id()/edit",
    meta: editLPJv2Mlx3CMeta || {},
    alias: editLPJv2Mlx3CMeta?.alias || [],
    redirect: editLPJv2Mlx3CMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/[warehouse_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLPJv2Mlx3CMeta?.name ?? "companies-warehouses-warehouse_id-edit___en",
    path: editLPJv2Mlx3CMeta?.path ?? "/en/companies/warehouses/:warehouse_id()/edit",
    meta: editLPJv2Mlx3CMeta || {},
    alias: editLPJv2Mlx3CMeta?.alias || [],
    redirect: editLPJv2Mlx3CMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/[warehouse_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexw69C1lKyneMeta?.name ?? "companies-warehouses-warehouse_id___id",
    path: indexw69C1lKyneMeta?.path ?? "/companies/warehouses/:warehouse_id()",
    meta: indexw69C1lKyneMeta || {},
    alias: indexw69C1lKyneMeta?.alias || [],
    redirect: indexw69C1lKyneMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/[warehouse_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexw69C1lKyneMeta?.name ?? "companies-warehouses-warehouse_id___en",
    path: indexw69C1lKyneMeta?.path ?? "/en/companies/warehouses/:warehouse_id()",
    meta: indexw69C1lKyneMeta || {},
    alias: indexw69C1lKyneMeta?.alias || [],
    redirect: indexw69C1lKyneMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/[warehouse_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createJBNKmp6gCpMeta?.name ?? "companies-warehouses-create___id",
    path: createJBNKmp6gCpMeta?.path ?? "/companies/warehouses/create",
    meta: createJBNKmp6gCpMeta || {},
    alias: createJBNKmp6gCpMeta?.alias || [],
    redirect: createJBNKmp6gCpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/create.vue").then(m => m.default || m)
  },
  {
    name: createJBNKmp6gCpMeta?.name ?? "companies-warehouses-create___en",
    path: createJBNKmp6gCpMeta?.path ?? "/en/companies/warehouses/create",
    meta: createJBNKmp6gCpMeta || {},
    alias: createJBNKmp6gCpMeta?.alias || [],
    redirect: createJBNKmp6gCpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/create.vue").then(m => m.default || m)
  },
  {
    name: createeCwGkmw29uMeta?.name ?? "companies-warehouses-forms-create___id",
    path: createeCwGkmw29uMeta?.path ?? "/companies/warehouses/forms/create",
    meta: createeCwGkmw29uMeta || {},
    alias: createeCwGkmw29uMeta?.alias || [],
    redirect: createeCwGkmw29uMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/forms/create.vue").then(m => m.default || m)
  },
  {
    name: createeCwGkmw29uMeta?.name ?? "companies-warehouses-forms-create___en",
    path: createeCwGkmw29uMeta?.path ?? "/en/companies/warehouses/forms/create",
    meta: createeCwGkmw29uMeta || {},
    alias: createeCwGkmw29uMeta?.alias || [],
    redirect: createeCwGkmw29uMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/forms/create.vue").then(m => m.default || m)
  },
  {
    name: indexm5DwbOJ2XLMeta?.name ?? "companies-warehouses___id",
    path: indexm5DwbOJ2XLMeta?.path ?? "/companies/warehouses",
    meta: indexm5DwbOJ2XLMeta || {},
    alias: indexm5DwbOJ2XLMeta?.alias || [],
    redirect: indexm5DwbOJ2XLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/index.vue").then(m => m.default || m)
  },
  {
    name: indexm5DwbOJ2XLMeta?.name ?? "companies-warehouses___en",
    path: indexm5DwbOJ2XLMeta?.path ?? "/en/companies/warehouses",
    meta: indexm5DwbOJ2XLMeta || {},
    alias: indexm5DwbOJ2XLMeta?.alias || [],
    redirect: indexm5DwbOJ2XLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/companies/warehouses/index.vue").then(m => m.default || m)
  },
  {
    name: indexeVB3XktwHsMeta?.name ?? "index___id",
    path: indexeVB3XktwHsMeta?.path ?? "/",
    meta: indexeVB3XktwHsMeta || {},
    alias: indexeVB3XktwHsMeta?.alias || [],
    redirect: indexeVB3XktwHsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexeVB3XktwHsMeta?.name ?? "index___en",
    path: indexeVB3XktwHsMeta?.path ?? "/en",
    meta: indexeVB3XktwHsMeta || {},
    alias: indexeVB3XktwHsMeta?.alias || [],
    redirect: indexeVB3XktwHsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhxG9laGrjtMeta?.name ?? "login___id",
    path: loginhxG9laGrjtMeta?.path ?? "/login",
    meta: loginhxG9laGrjtMeta || {},
    alias: loginhxG9laGrjtMeta?.alias || [],
    redirect: loginhxG9laGrjtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhxG9laGrjtMeta?.name ?? "login___en",
    path: loginhxG9laGrjtMeta?.path ?? "/en/login",
    meta: loginhxG9laGrjtMeta || {},
    alias: loginhxG9laGrjtMeta?.alias || [],
    redirect: loginhxG9laGrjtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/login.vue").then(m => m.default || m)
  },
  {
    name: editdHJPIMEw5XMeta?.name ?? "stores-store_id-account-types-account_type_id-accounts-account_id-edit___id",
    path: editdHJPIMEw5XMeta?.path ?? "/stores/:store_id()/account-types/:account_type_id()/accounts/:account_id()/edit",
    meta: editdHJPIMEw5XMeta || {},
    alias: editdHJPIMEw5XMeta?.alias || [],
    redirect: editdHJPIMEw5XMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/[account_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editdHJPIMEw5XMeta?.name ?? "stores-store_id-account-types-account_type_id-accounts-account_id-edit___en",
    path: editdHJPIMEw5XMeta?.path ?? "/en/stores/:store_id()/account-types/:account_type_id()/accounts/:account_id()/edit",
    meta: editdHJPIMEw5XMeta || {},
    alias: editdHJPIMEw5XMeta?.alias || [],
    redirect: editdHJPIMEw5XMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/[account_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexbkasBhivCKMeta?.name ?? "stores-store_id-account-types-account_type_id-accounts-account_id___id",
    path: indexbkasBhivCKMeta?.path ?? "/stores/:store_id()/account-types/:account_type_id()/accounts/:account_id()",
    meta: indexbkasBhivCKMeta || {},
    alias: indexbkasBhivCKMeta?.alias || [],
    redirect: indexbkasBhivCKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/[account_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbkasBhivCKMeta?.name ?? "stores-store_id-account-types-account_type_id-accounts-account_id___en",
    path: indexbkasBhivCKMeta?.path ?? "/en/stores/:store_id()/account-types/:account_type_id()/accounts/:account_id()",
    meta: indexbkasBhivCKMeta || {},
    alias: indexbkasBhivCKMeta?.alias || [],
    redirect: indexbkasBhivCKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/[account_id]/index.vue").then(m => m.default || m)
  },
  {
    name: create2eNXXwiaQTMeta?.name ?? "stores-store_id-account-types-account_type_id-accounts-create___id",
    path: create2eNXXwiaQTMeta?.path ?? "/stores/:store_id()/account-types/:account_type_id()/accounts/create",
    meta: create2eNXXwiaQTMeta || {},
    alias: create2eNXXwiaQTMeta?.alias || [],
    redirect: create2eNXXwiaQTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/create.vue").then(m => m.default || m)
  },
  {
    name: create2eNXXwiaQTMeta?.name ?? "stores-store_id-account-types-account_type_id-accounts-create___en",
    path: create2eNXXwiaQTMeta?.path ?? "/en/stores/:store_id()/account-types/:account_type_id()/accounts/create",
    meta: create2eNXXwiaQTMeta || {},
    alias: create2eNXXwiaQTMeta?.alias || [],
    redirect: create2eNXXwiaQTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/create.vue").then(m => m.default || m)
  },
  {
    name: indexUJbBq9pbVyMeta?.name ?? "stores-store_id-account-types-account_type_id-accounts___id",
    path: indexUJbBq9pbVyMeta?.path ?? "/stores/:store_id()/account-types/:account_type_id()/accounts",
    meta: indexUJbBq9pbVyMeta || {},
    alias: indexUJbBq9pbVyMeta?.alias || [],
    redirect: indexUJbBq9pbVyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexUJbBq9pbVyMeta?.name ?? "stores-store_id-account-types-account_type_id-accounts___en",
    path: indexUJbBq9pbVyMeta?.path ?? "/en/stores/:store_id()/account-types/:account_type_id()/accounts",
    meta: indexUJbBq9pbVyMeta || {},
    alias: indexUJbBq9pbVyMeta?.alias || [],
    redirect: indexUJbBq9pbVyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: editCvrmRQjr1tMeta?.name ?? "stores-store_id-account-types-account_type_id-edit___id",
    path: editCvrmRQjr1tMeta?.path ?? "/stores/:store_id()/account-types/:account_type_id()/edit",
    meta: editCvrmRQjr1tMeta || {},
    alias: editCvrmRQjr1tMeta?.alias || [],
    redirect: editCvrmRQjr1tMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editCvrmRQjr1tMeta?.name ?? "stores-store_id-account-types-account_type_id-edit___en",
    path: editCvrmRQjr1tMeta?.path ?? "/en/stores/:store_id()/account-types/:account_type_id()/edit",
    meta: editCvrmRQjr1tMeta || {},
    alias: editCvrmRQjr1tMeta?.alias || [],
    redirect: editCvrmRQjr1tMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZRgyDjNgK6Meta?.name ?? "stores-store_id-account-types-account_type_id___id",
    path: indexZRgyDjNgK6Meta?.path ?? "/stores/:store_id()/account-types/:account_type_id()",
    meta: indexZRgyDjNgK6Meta || {},
    alias: indexZRgyDjNgK6Meta?.alias || [],
    redirect: indexZRgyDjNgK6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZRgyDjNgK6Meta?.name ?? "stores-store_id-account-types-account_type_id___en",
    path: indexZRgyDjNgK6Meta?.path ?? "/en/stores/:store_id()/account-types/:account_type_id()",
    meta: indexZRgyDjNgK6Meta || {},
    alias: indexZRgyDjNgK6Meta?.alias || [],
    redirect: indexZRgyDjNgK6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/[account_type_id]/index.vue").then(m => m.default || m)
  },
  {
    name: creategvUzqfKzMsMeta?.name ?? "stores-store_id-account-types-create___id",
    path: creategvUzqfKzMsMeta?.path ?? "/stores/:store_id()/account-types/create",
    meta: creategvUzqfKzMsMeta || {},
    alias: creategvUzqfKzMsMeta?.alias || [],
    redirect: creategvUzqfKzMsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/create.vue").then(m => m.default || m)
  },
  {
    name: creategvUzqfKzMsMeta?.name ?? "stores-store_id-account-types-create___en",
    path: creategvUzqfKzMsMeta?.path ?? "/en/stores/:store_id()/account-types/create",
    meta: creategvUzqfKzMsMeta || {},
    alias: creategvUzqfKzMsMeta?.alias || [],
    redirect: creategvUzqfKzMsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/create.vue").then(m => m.default || m)
  },
  {
    name: indexzl4NY0ArswMeta?.name ?? "stores-store_id-account-types___id",
    path: indexzl4NY0ArswMeta?.path ?? "/stores/:store_id()/account-types",
    meta: indexzl4NY0ArswMeta || {},
    alias: indexzl4NY0ArswMeta?.alias || [],
    redirect: indexzl4NY0ArswMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/index.vue").then(m => m.default || m)
  },
  {
    name: indexzl4NY0ArswMeta?.name ?? "stores-store_id-account-types___en",
    path: indexzl4NY0ArswMeta?.path ?? "/en/stores/:store_id()/account-types",
    meta: indexzl4NY0ArswMeta || {},
    alias: indexzl4NY0ArswMeta?.alias || [],
    redirect: indexzl4NY0ArswMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/account-types/index.vue").then(m => m.default || m)
  },
  {
    name: editf1EwleiDiPMeta?.name ?? "stores-store_id-asset-depreciations-asset_depreciation_id-edit___id",
    path: editf1EwleiDiPMeta?.path ?? "/stores/:store_id()/asset-depreciations/:asset_depreciation_id()/edit",
    meta: editf1EwleiDiPMeta || {},
    alias: editf1EwleiDiPMeta?.alias || [],
    redirect: editf1EwleiDiPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/[asset_depreciation_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editf1EwleiDiPMeta?.name ?? "stores-store_id-asset-depreciations-asset_depreciation_id-edit___en",
    path: editf1EwleiDiPMeta?.path ?? "/en/stores/:store_id()/asset-depreciations/:asset_depreciation_id()/edit",
    meta: editf1EwleiDiPMeta || {},
    alias: editf1EwleiDiPMeta?.alias || [],
    redirect: editf1EwleiDiPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/[asset_depreciation_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQYJe8kXZc4Meta?.name ?? "stores-store_id-asset-depreciations-asset_depreciation_id___id",
    path: indexQYJe8kXZc4Meta?.path ?? "/stores/:store_id()/asset-depreciations/:asset_depreciation_id()",
    meta: indexQYJe8kXZc4Meta || {},
    alias: indexQYJe8kXZc4Meta?.alias || [],
    redirect: indexQYJe8kXZc4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/[asset_depreciation_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQYJe8kXZc4Meta?.name ?? "stores-store_id-asset-depreciations-asset_depreciation_id___en",
    path: indexQYJe8kXZc4Meta?.path ?? "/en/stores/:store_id()/asset-depreciations/:asset_depreciation_id()",
    meta: indexQYJe8kXZc4Meta || {},
    alias: indexQYJe8kXZc4Meta?.alias || [],
    redirect: indexQYJe8kXZc4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/[asset_depreciation_id]/index.vue").then(m => m.default || m)
  },
  {
    name: create7OvtrsRcO5Meta?.name ?? "stores-store_id-asset-depreciations-create___id",
    path: create7OvtrsRcO5Meta?.path ?? "/stores/:store_id()/asset-depreciations/create",
    meta: create7OvtrsRcO5Meta || {},
    alias: create7OvtrsRcO5Meta?.alias || [],
    redirect: create7OvtrsRcO5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/create.vue").then(m => m.default || m)
  },
  {
    name: create7OvtrsRcO5Meta?.name ?? "stores-store_id-asset-depreciations-create___en",
    path: create7OvtrsRcO5Meta?.path ?? "/en/stores/:store_id()/asset-depreciations/create",
    meta: create7OvtrsRcO5Meta || {},
    alias: create7OvtrsRcO5Meta?.alias || [],
    redirect: create7OvtrsRcO5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/create.vue").then(m => m.default || m)
  },
  {
    name: index49Lqa1LVKtMeta?.name ?? "stores-store_id-asset-depreciations___id",
    path: index49Lqa1LVKtMeta?.path ?? "/stores/:store_id()/asset-depreciations",
    meta: index49Lqa1LVKtMeta || {},
    alias: index49Lqa1LVKtMeta?.alias || [],
    redirect: index49Lqa1LVKtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/index.vue").then(m => m.default || m)
  },
  {
    name: index49Lqa1LVKtMeta?.name ?? "stores-store_id-asset-depreciations___en",
    path: index49Lqa1LVKtMeta?.path ?? "/en/stores/:store_id()/asset-depreciations",
    meta: index49Lqa1LVKtMeta || {},
    alias: index49Lqa1LVKtMeta?.alias || [],
    redirect: index49Lqa1LVKtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-depreciations/index.vue").then(m => m.default || m)
  },
  {
    name: edit93YnoMI8KEMeta?.name ?? "stores-store_id-asset-types-asset_type_id-edit___id",
    path: edit93YnoMI8KEMeta?.path ?? "/stores/:store_id()/asset-types/:asset_type_id()/edit",
    meta: edit93YnoMI8KEMeta || {},
    alias: edit93YnoMI8KEMeta?.alias || [],
    redirect: edit93YnoMI8KEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/[asset_type_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit93YnoMI8KEMeta?.name ?? "stores-store_id-asset-types-asset_type_id-edit___en",
    path: edit93YnoMI8KEMeta?.path ?? "/en/stores/:store_id()/asset-types/:asset_type_id()/edit",
    meta: edit93YnoMI8KEMeta || {},
    alias: edit93YnoMI8KEMeta?.alias || [],
    redirect: edit93YnoMI8KEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/[asset_type_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexK7mKmtSjrNMeta?.name ?? "stores-store_id-asset-types-asset_type_id___id",
    path: indexK7mKmtSjrNMeta?.path ?? "/stores/:store_id()/asset-types/:asset_type_id()",
    meta: indexK7mKmtSjrNMeta || {},
    alias: indexK7mKmtSjrNMeta?.alias || [],
    redirect: indexK7mKmtSjrNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/[asset_type_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexK7mKmtSjrNMeta?.name ?? "stores-store_id-asset-types-asset_type_id___en",
    path: indexK7mKmtSjrNMeta?.path ?? "/en/stores/:store_id()/asset-types/:asset_type_id()",
    meta: indexK7mKmtSjrNMeta || {},
    alias: indexK7mKmtSjrNMeta?.alias || [],
    redirect: indexK7mKmtSjrNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/[asset_type_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createyBkLP4wZfmMeta?.name ?? "stores-store_id-asset-types-create___id",
    path: createyBkLP4wZfmMeta?.path ?? "/stores/:store_id()/asset-types/create",
    meta: createyBkLP4wZfmMeta || {},
    alias: createyBkLP4wZfmMeta?.alias || [],
    redirect: createyBkLP4wZfmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/create.vue").then(m => m.default || m)
  },
  {
    name: createyBkLP4wZfmMeta?.name ?? "stores-store_id-asset-types-create___en",
    path: createyBkLP4wZfmMeta?.path ?? "/en/stores/:store_id()/asset-types/create",
    meta: createyBkLP4wZfmMeta || {},
    alias: createyBkLP4wZfmMeta?.alias || [],
    redirect: createyBkLP4wZfmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/create.vue").then(m => m.default || m)
  },
  {
    name: indexKEKbGjumEtMeta?.name ?? "stores-store_id-asset-types___id",
    path: indexKEKbGjumEtMeta?.path ?? "/stores/:store_id()/asset-types",
    meta: indexKEKbGjumEtMeta || {},
    alias: indexKEKbGjumEtMeta?.alias || [],
    redirect: indexKEKbGjumEtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/index.vue").then(m => m.default || m)
  },
  {
    name: indexKEKbGjumEtMeta?.name ?? "stores-store_id-asset-types___en",
    path: indexKEKbGjumEtMeta?.path ?? "/en/stores/:store_id()/asset-types",
    meta: indexKEKbGjumEtMeta || {},
    alias: indexKEKbGjumEtMeta?.alias || [],
    redirect: indexKEKbGjumEtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/asset-types/index.vue").then(m => m.default || m)
  },
  {
    name: editBPmP9KYiYIMeta?.name ?? "stores-store_id-assets-asset_id-edit___id",
    path: editBPmP9KYiYIMeta?.path ?? "/stores/:store_id()/assets/:asset_id()/edit",
    meta: editBPmP9KYiYIMeta || {},
    alias: editBPmP9KYiYIMeta?.alias || [],
    redirect: editBPmP9KYiYIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/[asset_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editBPmP9KYiYIMeta?.name ?? "stores-store_id-assets-asset_id-edit___en",
    path: editBPmP9KYiYIMeta?.path ?? "/en/stores/:store_id()/assets/:asset_id()/edit",
    meta: editBPmP9KYiYIMeta || {},
    alias: editBPmP9KYiYIMeta?.alias || [],
    redirect: editBPmP9KYiYIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/[asset_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexDZZEzSrqCEMeta?.name ?? "stores-store_id-assets-asset_id___id",
    path: indexDZZEzSrqCEMeta?.path ?? "/stores/:store_id()/assets/:asset_id()",
    meta: indexDZZEzSrqCEMeta || {},
    alias: indexDZZEzSrqCEMeta?.alias || [],
    redirect: indexDZZEzSrqCEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/[asset_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDZZEzSrqCEMeta?.name ?? "stores-store_id-assets-asset_id___en",
    path: indexDZZEzSrqCEMeta?.path ?? "/en/stores/:store_id()/assets/:asset_id()",
    meta: indexDZZEzSrqCEMeta || {},
    alias: indexDZZEzSrqCEMeta?.alias || [],
    redirect: indexDZZEzSrqCEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/[asset_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createeapC64k5laMeta?.name ?? "stores-store_id-assets-create___id",
    path: createeapC64k5laMeta?.path ?? "/stores/:store_id()/assets/create",
    meta: createeapC64k5laMeta || {},
    alias: createeapC64k5laMeta?.alias || [],
    redirect: createeapC64k5laMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/create.vue").then(m => m.default || m)
  },
  {
    name: createeapC64k5laMeta?.name ?? "stores-store_id-assets-create___en",
    path: createeapC64k5laMeta?.path ?? "/en/stores/:store_id()/assets/create",
    meta: createeapC64k5laMeta || {},
    alias: createeapC64k5laMeta?.alias || [],
    redirect: createeapC64k5laMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/create.vue").then(m => m.default || m)
  },
  {
    name: indexYNRBDcC7LAMeta?.name ?? "stores-store_id-assets___id",
    path: indexYNRBDcC7LAMeta?.path ?? "/stores/:store_id()/assets",
    meta: indexYNRBDcC7LAMeta || {},
    alias: indexYNRBDcC7LAMeta?.alias || [],
    redirect: indexYNRBDcC7LAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/index.vue").then(m => m.default || m)
  },
  {
    name: indexYNRBDcC7LAMeta?.name ?? "stores-store_id-assets___en",
    path: indexYNRBDcC7LAMeta?.path ?? "/en/stores/:store_id()/assets",
    meta: indexYNRBDcC7LAMeta || {},
    alias: indexYNRBDcC7LAMeta?.alias || [],
    redirect: indexYNRBDcC7LAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/assets/index.vue").then(m => m.default || m)
  },
  {
    name: edit1JLN6gB7IrMeta?.name ?? "stores-store_id-categories-category_id-edit___id",
    path: edit1JLN6gB7IrMeta?.path ?? "/stores/:store_id()/categories/:category_id()/edit",
    meta: edit1JLN6gB7IrMeta || {},
    alias: edit1JLN6gB7IrMeta?.alias || [],
    redirect: edit1JLN6gB7IrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit1JLN6gB7IrMeta?.name ?? "stores-store_id-categories-category_id-edit___en",
    path: edit1JLN6gB7IrMeta?.path ?? "/en/stores/:store_id()/categories/:category_id()/edit",
    meta: edit1JLN6gB7IrMeta || {},
    alias: edit1JLN6gB7IrMeta?.alias || [],
    redirect: edit1JLN6gB7IrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexqb0Nru7IsvMeta?.name ?? "stores-store_id-categories-category_id___id",
    path: indexqb0Nru7IsvMeta?.path ?? "/stores/:store_id()/categories/:category_id()",
    meta: indexqb0Nru7IsvMeta || {},
    alias: indexqb0Nru7IsvMeta?.alias || [],
    redirect: indexqb0Nru7IsvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqb0Nru7IsvMeta?.name ?? "stores-store_id-categories-category_id___en",
    path: indexqb0Nru7IsvMeta?.path ?? "/en/stores/:store_id()/categories/:category_id()",
    meta: indexqb0Nru7IsvMeta || {},
    alias: indexqb0Nru7IsvMeta?.alias || [],
    redirect: indexqb0Nru7IsvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/index.vue").then(m => m.default || m)
  },
  {
    name: editZt44JMPNSBMeta?.name ?? "stores-store_id-categories-category_id-sub-categories-sub_category_id-edit___id",
    path: editZt44JMPNSBMeta?.path ?? "/stores/:store_id()/categories/:category_id()/sub-categories/:sub_category_id()/edit",
    meta: editZt44JMPNSBMeta || {},
    alias: editZt44JMPNSBMeta?.alias || [],
    redirect: editZt44JMPNSBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/[sub_category_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editZt44JMPNSBMeta?.name ?? "stores-store_id-categories-category_id-sub-categories-sub_category_id-edit___en",
    path: editZt44JMPNSBMeta?.path ?? "/en/stores/:store_id()/categories/:category_id()/sub-categories/:sub_category_id()/edit",
    meta: editZt44JMPNSBMeta || {},
    alias: editZt44JMPNSBMeta?.alias || [],
    redirect: editZt44JMPNSBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/[sub_category_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexmEVHlqdK9wMeta?.name ?? "stores-store_id-categories-category_id-sub-categories-sub_category_id___id",
    path: indexmEVHlqdK9wMeta?.path ?? "/stores/:store_id()/categories/:category_id()/sub-categories/:sub_category_id()",
    meta: indexmEVHlqdK9wMeta || {},
    alias: indexmEVHlqdK9wMeta?.alias || [],
    redirect: indexmEVHlqdK9wMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/[sub_category_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEVHlqdK9wMeta?.name ?? "stores-store_id-categories-category_id-sub-categories-sub_category_id___en",
    path: indexmEVHlqdK9wMeta?.path ?? "/en/stores/:store_id()/categories/:category_id()/sub-categories/:sub_category_id()",
    meta: indexmEVHlqdK9wMeta || {},
    alias: indexmEVHlqdK9wMeta?.alias || [],
    redirect: indexmEVHlqdK9wMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/[sub_category_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createNIASO3W826Meta?.name ?? "stores-store_id-categories-category_id-sub-categories-create___id",
    path: createNIASO3W826Meta?.path ?? "/stores/:store_id()/categories/:category_id()/sub-categories/create",
    meta: createNIASO3W826Meta || {},
    alias: createNIASO3W826Meta?.alias || [],
    redirect: createNIASO3W826Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/create.vue").then(m => m.default || m)
  },
  {
    name: createNIASO3W826Meta?.name ?? "stores-store_id-categories-category_id-sub-categories-create___en",
    path: createNIASO3W826Meta?.path ?? "/en/stores/:store_id()/categories/:category_id()/sub-categories/create",
    meta: createNIASO3W826Meta || {},
    alias: createNIASO3W826Meta?.alias || [],
    redirect: createNIASO3W826Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/create.vue").then(m => m.default || m)
  },
  {
    name: indexrJXfD4DaoUMeta?.name ?? "stores-store_id-categories-category_id-sub-categories___id",
    path: indexrJXfD4DaoUMeta?.path ?? "/stores/:store_id()/categories/:category_id()/sub-categories",
    meta: indexrJXfD4DaoUMeta || {},
    alias: indexrJXfD4DaoUMeta?.alias || [],
    redirect: indexrJXfD4DaoUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexrJXfD4DaoUMeta?.name ?? "stores-store_id-categories-category_id-sub-categories___en",
    path: indexrJXfD4DaoUMeta?.path ?? "/en/stores/:store_id()/categories/:category_id()/sub-categories",
    meta: indexrJXfD4DaoUMeta || {},
    alias: indexrJXfD4DaoUMeta?.alias || [],
    redirect: indexrJXfD4DaoUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/[category_id]/sub-categories/index.vue").then(m => m.default || m)
  },
  {
    name: createMnpIzeANYmMeta?.name ?? "stores-store_id-categories-create___id",
    path: createMnpIzeANYmMeta?.path ?? "/stores/:store_id()/categories/create",
    meta: createMnpIzeANYmMeta || {},
    alias: createMnpIzeANYmMeta?.alias || [],
    redirect: createMnpIzeANYmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/create.vue").then(m => m.default || m)
  },
  {
    name: createMnpIzeANYmMeta?.name ?? "stores-store_id-categories-create___en",
    path: createMnpIzeANYmMeta?.path ?? "/en/stores/:store_id()/categories/create",
    meta: createMnpIzeANYmMeta || {},
    alias: createMnpIzeANYmMeta?.alias || [],
    redirect: createMnpIzeANYmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/create.vue").then(m => m.default || m)
  },
  {
    name: indexJfvs1yczprMeta?.name ?? "stores-store_id-categories___id",
    path: indexJfvs1yczprMeta?.path ?? "/stores/:store_id()/categories",
    meta: indexJfvs1yczprMeta || {},
    alias: indexJfvs1yczprMeta?.alias || [],
    redirect: indexJfvs1yczprMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexJfvs1yczprMeta?.name ?? "stores-store_id-categories___en",
    path: indexJfvs1yczprMeta?.path ?? "/en/stores/:store_id()/categories",
    meta: indexJfvs1yczprMeta || {},
    alias: indexJfvs1yczprMeta?.alias || [],
    redirect: indexJfvs1yczprMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/categories/index.vue").then(m => m.default || m)
  },
  {
    name: editXC7NdhG0FhMeta?.name ?? "stores-store_id-customers-customer_id-edit___id",
    path: editXC7NdhG0FhMeta?.path ?? "/stores/:store_id()/customers/:customer_id()/edit",
    meta: editXC7NdhG0FhMeta || {},
    alias: editXC7NdhG0FhMeta?.alias || [],
    redirect: editXC7NdhG0FhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/[customer_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editXC7NdhG0FhMeta?.name ?? "stores-store_id-customers-customer_id-edit___en",
    path: editXC7NdhG0FhMeta?.path ?? "/en/stores/:store_id()/customers/:customer_id()/edit",
    meta: editXC7NdhG0FhMeta || {},
    alias: editXC7NdhG0FhMeta?.alias || [],
    redirect: editXC7NdhG0FhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/[customer_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexx34x17xSfCMeta?.name ?? "stores-store_id-customers-customer_id___id",
    path: indexx34x17xSfCMeta?.path ?? "/stores/:store_id()/customers/:customer_id()",
    meta: indexx34x17xSfCMeta || {},
    alias: indexx34x17xSfCMeta?.alias || [],
    redirect: indexx34x17xSfCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/[customer_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexx34x17xSfCMeta?.name ?? "stores-store_id-customers-customer_id___en",
    path: indexx34x17xSfCMeta?.path ?? "/en/stores/:store_id()/customers/:customer_id()",
    meta: indexx34x17xSfCMeta || {},
    alias: indexx34x17xSfCMeta?.alias || [],
    redirect: indexx34x17xSfCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/[customer_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createqTp1CDkbzNMeta?.name ?? "stores-store_id-customers-create___id",
    path: createqTp1CDkbzNMeta?.path ?? "/stores/:store_id()/customers/create",
    meta: createqTp1CDkbzNMeta || {},
    alias: createqTp1CDkbzNMeta?.alias || [],
    redirect: createqTp1CDkbzNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/create.vue").then(m => m.default || m)
  },
  {
    name: createqTp1CDkbzNMeta?.name ?? "stores-store_id-customers-create___en",
    path: createqTp1CDkbzNMeta?.path ?? "/en/stores/:store_id()/customers/create",
    meta: createqTp1CDkbzNMeta || {},
    alias: createqTp1CDkbzNMeta?.alias || [],
    redirect: createqTp1CDkbzNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/create.vue").then(m => m.default || m)
  },
  {
    name: index88Tpttefd8Meta?.name ?? "stores-store_id-customers___id",
    path: index88Tpttefd8Meta?.path ?? "/stores/:store_id()/customers",
    meta: index88Tpttefd8Meta || {},
    alias: index88Tpttefd8Meta?.alias || [],
    redirect: index88Tpttefd8Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index88Tpttefd8Meta?.name ?? "stores-store_id-customers___en",
    path: index88Tpttefd8Meta?.path ?? "/en/stores/:store_id()/customers",
    meta: index88Tpttefd8Meta || {},
    alias: index88Tpttefd8Meta?.alias || [],
    redirect: index88Tpttefd8Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexoeb0zYGovaMeta?.name ?? "stores-store_id-dashboard___id",
    path: indexoeb0zYGovaMeta?.path ?? "/stores/:store_id()/dashboard",
    meta: indexoeb0zYGovaMeta || {},
    alias: indexoeb0zYGovaMeta?.alias || [],
    redirect: indexoeb0zYGovaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexoeb0zYGovaMeta?.name ?? "stores-store_id-dashboard___en",
    path: indexoeb0zYGovaMeta?.path ?? "/en/stores/:store_id()/dashboard",
    meta: indexoeb0zYGovaMeta || {},
    alias: indexoeb0zYGovaMeta?.alias || [],
    redirect: indexoeb0zYGovaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexkRxuX3gNAEMeta?.name ?? "stores-store_id-deposits-deposit_id___id",
    path: indexkRxuX3gNAEMeta?.path ?? "/stores/:store_id()/deposits/:deposit_id()",
    meta: indexkRxuX3gNAEMeta || {},
    alias: indexkRxuX3gNAEMeta?.alias || [],
    redirect: indexkRxuX3gNAEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/deposits/[deposit_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkRxuX3gNAEMeta?.name ?? "stores-store_id-deposits-deposit_id___en",
    path: indexkRxuX3gNAEMeta?.path ?? "/en/stores/:store_id()/deposits/:deposit_id()",
    meta: indexkRxuX3gNAEMeta || {},
    alias: indexkRxuX3gNAEMeta?.alias || [],
    redirect: indexkRxuX3gNAEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/deposits/[deposit_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createCy96CosBznMeta?.name ?? "stores-store_id-deposits-create___id",
    path: createCy96CosBznMeta?.path ?? "/stores/:store_id()/deposits/create",
    meta: createCy96CosBznMeta || {},
    alias: createCy96CosBznMeta?.alias || [],
    redirect: createCy96CosBznMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/deposits/create.vue").then(m => m.default || m)
  },
  {
    name: createCy96CosBznMeta?.name ?? "stores-store_id-deposits-create___en",
    path: createCy96CosBznMeta?.path ?? "/en/stores/:store_id()/deposits/create",
    meta: createCy96CosBznMeta || {},
    alias: createCy96CosBznMeta?.alias || [],
    redirect: createCy96CosBznMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/deposits/create.vue").then(m => m.default || m)
  },
  {
    name: index7Y7Bf6nghjMeta?.name ?? "stores-store_id-deposits___id",
    path: index7Y7Bf6nghjMeta?.path ?? "/stores/:store_id()/deposits",
    meta: index7Y7Bf6nghjMeta || {},
    alias: index7Y7Bf6nghjMeta?.alias || [],
    redirect: index7Y7Bf6nghjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/deposits/index.vue").then(m => m.default || m)
  },
  {
    name: index7Y7Bf6nghjMeta?.name ?? "stores-store_id-deposits___en",
    path: index7Y7Bf6nghjMeta?.path ?? "/en/stores/:store_id()/deposits",
    meta: index7Y7Bf6nghjMeta || {},
    alias: index7Y7Bf6nghjMeta?.alias || [],
    redirect: index7Y7Bf6nghjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/deposits/index.vue").then(m => m.default || m)
  },
  {
    name: indextgaPavHm4FMeta?.name ?? "stores-store_id-expedition-transactions-expedition_transaction_id___id",
    path: indextgaPavHm4FMeta?.path ?? "/stores/:store_id()/expedition-transactions/:expedition_transaction_id()",
    meta: indextgaPavHm4FMeta || {},
    alias: indextgaPavHm4FMeta?.alias || [],
    redirect: indextgaPavHm4FMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expedition-transactions/[expedition_transaction_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indextgaPavHm4FMeta?.name ?? "stores-store_id-expedition-transactions-expedition_transaction_id___en",
    path: indextgaPavHm4FMeta?.path ?? "/en/stores/:store_id()/expedition-transactions/:expedition_transaction_id()",
    meta: indextgaPavHm4FMeta || {},
    alias: indextgaPavHm4FMeta?.alias || [],
    redirect: indextgaPavHm4FMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expedition-transactions/[expedition_transaction_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiG9ELFJHsSMeta?.name ?? "stores-store_id-expedition-transactions___id",
    path: indexiG9ELFJHsSMeta?.path ?? "/stores/:store_id()/expedition-transactions",
    meta: indexiG9ELFJHsSMeta || {},
    alias: indexiG9ELFJHsSMeta?.alias || [],
    redirect: indexiG9ELFJHsSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expedition-transactions/index.vue").then(m => m.default || m)
  },
  {
    name: indexiG9ELFJHsSMeta?.name ?? "stores-store_id-expedition-transactions___en",
    path: indexiG9ELFJHsSMeta?.path ?? "/en/stores/:store_id()/expedition-transactions",
    meta: indexiG9ELFJHsSMeta || {},
    alias: indexiG9ELFJHsSMeta?.alias || [],
    redirect: indexiG9ELFJHsSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expedition-transactions/index.vue").then(m => m.default || m)
  },
  {
    name: editqRX06wlhYcMeta?.name ?? "stores-store_id-expeditions-expedition_id-edit___id",
    path: editqRX06wlhYcMeta?.path ?? "/stores/:store_id()/expeditions/:expedition_id()/edit",
    meta: editqRX06wlhYcMeta || {},
    alias: editqRX06wlhYcMeta?.alias || [],
    redirect: editqRX06wlhYcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/[expedition_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editqRX06wlhYcMeta?.name ?? "stores-store_id-expeditions-expedition_id-edit___en",
    path: editqRX06wlhYcMeta?.path ?? "/en/stores/:store_id()/expeditions/:expedition_id()/edit",
    meta: editqRX06wlhYcMeta || {},
    alias: editqRX06wlhYcMeta?.alias || [],
    redirect: editqRX06wlhYcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/[expedition_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexpK45ytmFWBMeta?.name ?? "stores-store_id-expeditions-expedition_id___id",
    path: indexpK45ytmFWBMeta?.path ?? "/stores/:store_id()/expeditions/:expedition_id()",
    meta: indexpK45ytmFWBMeta || {},
    alias: indexpK45ytmFWBMeta?.alias || [],
    redirect: indexpK45ytmFWBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/[expedition_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpK45ytmFWBMeta?.name ?? "stores-store_id-expeditions-expedition_id___en",
    path: indexpK45ytmFWBMeta?.path ?? "/en/stores/:store_id()/expeditions/:expedition_id()",
    meta: indexpK45ytmFWBMeta || {},
    alias: indexpK45ytmFWBMeta?.alias || [],
    redirect: indexpK45ytmFWBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/[expedition_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createfFeik7OJIIMeta?.name ?? "stores-store_id-expeditions-create___id",
    path: createfFeik7OJIIMeta?.path ?? "/stores/:store_id()/expeditions/create",
    meta: createfFeik7OJIIMeta || {},
    alias: createfFeik7OJIIMeta?.alias || [],
    redirect: createfFeik7OJIIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/create.vue").then(m => m.default || m)
  },
  {
    name: createfFeik7OJIIMeta?.name ?? "stores-store_id-expeditions-create___en",
    path: createfFeik7OJIIMeta?.path ?? "/en/stores/:store_id()/expeditions/create",
    meta: createfFeik7OJIIMeta || {},
    alias: createfFeik7OJIIMeta?.alias || [],
    redirect: createfFeik7OJIIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/create.vue").then(m => m.default || m)
  },
  {
    name: indexs4JPnAYEJvMeta?.name ?? "stores-store_id-expeditions___id",
    path: indexs4JPnAYEJvMeta?.path ?? "/stores/:store_id()/expeditions",
    meta: indexs4JPnAYEJvMeta || {},
    alias: indexs4JPnAYEJvMeta?.alias || [],
    redirect: indexs4JPnAYEJvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexs4JPnAYEJvMeta?.name ?? "stores-store_id-expeditions___en",
    path: indexs4JPnAYEJvMeta?.path ?? "/en/stores/:store_id()/expeditions",
    meta: indexs4JPnAYEJvMeta || {},
    alias: indexs4JPnAYEJvMeta?.alias || [],
    redirect: indexs4JPnAYEJvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expeditions/index.vue").then(m => m.default || m)
  },
  {
    name: index8HhMxt0hZGMeta?.name ?? "stores-store_id-expenses-expense_id___id",
    path: index8HhMxt0hZGMeta?.path ?? "/stores/:store_id()/expenses/:expense_id()",
    meta: index8HhMxt0hZGMeta || {},
    alias: index8HhMxt0hZGMeta?.alias || [],
    redirect: index8HhMxt0hZGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expenses/[expense_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index8HhMxt0hZGMeta?.name ?? "stores-store_id-expenses-expense_id___en",
    path: index8HhMxt0hZGMeta?.path ?? "/en/stores/:store_id()/expenses/:expense_id()",
    meta: index8HhMxt0hZGMeta || {},
    alias: index8HhMxt0hZGMeta?.alias || [],
    redirect: index8HhMxt0hZGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expenses/[expense_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createYteDWudw0WMeta?.name ?? "stores-store_id-expenses-create___id",
    path: createYteDWudw0WMeta?.path ?? "/stores/:store_id()/expenses/create",
    meta: createYteDWudw0WMeta || {},
    alias: createYteDWudw0WMeta?.alias || [],
    redirect: createYteDWudw0WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expenses/create.vue").then(m => m.default || m)
  },
  {
    name: createYteDWudw0WMeta?.name ?? "stores-store_id-expenses-create___en",
    path: createYteDWudw0WMeta?.path ?? "/en/stores/:store_id()/expenses/create",
    meta: createYteDWudw0WMeta || {},
    alias: createYteDWudw0WMeta?.alias || [],
    redirect: createYteDWudw0WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expenses/create.vue").then(m => m.default || m)
  },
  {
    name: indexZ1ALKXvoQkMeta?.name ?? "stores-store_id-expenses___id",
    path: indexZ1ALKXvoQkMeta?.path ?? "/stores/:store_id()/expenses",
    meta: indexZ1ALKXvoQkMeta || {},
    alias: indexZ1ALKXvoQkMeta?.alias || [],
    redirect: indexZ1ALKXvoQkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expenses/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ1ALKXvoQkMeta?.name ?? "stores-store_id-expenses___en",
    path: indexZ1ALKXvoQkMeta?.path ?? "/en/stores/:store_id()/expenses",
    meta: indexZ1ALKXvoQkMeta || {},
    alias: indexZ1ALKXvoQkMeta?.alias || [],
    redirect: indexZ1ALKXvoQkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/expenses/index.vue").then(m => m.default || m)
  },
  {
    name: editaakw4Lt28xMeta?.name ?? "stores-store_id-general-journals-general_journal_id-edit___id",
    path: editaakw4Lt28xMeta?.path ?? "/stores/:store_id()/general-journals/:general_journal_id()/edit",
    meta: editaakw4Lt28xMeta || {},
    alias: editaakw4Lt28xMeta?.alias || [],
    redirect: editaakw4Lt28xMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editaakw4Lt28xMeta?.name ?? "stores-store_id-general-journals-general_journal_id-edit___en",
    path: editaakw4Lt28xMeta?.path ?? "/en/stores/:store_id()/general-journals/:general_journal_id()/edit",
    meta: editaakw4Lt28xMeta || {},
    alias: editaakw4Lt28xMeta?.alias || [],
    redirect: editaakw4Lt28xMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLSdgHLGZcUMeta?.name ?? "stores-store_id-general-journals-general_journal_id-general-journal-entries-general_journal_entry_id-edit___id",
    path: editLSdgHLGZcUMeta?.path ?? "/stores/:store_id()/general-journals/:general_journal_id()/general-journal-entries/:general_journal_entry_id()/edit",
    meta: editLSdgHLGZcUMeta || {},
    alias: editLSdgHLGZcUMeta?.alias || [],
    redirect: editLSdgHLGZcUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/[general_journal_entry_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLSdgHLGZcUMeta?.name ?? "stores-store_id-general-journals-general_journal_id-general-journal-entries-general_journal_entry_id-edit___en",
    path: editLSdgHLGZcUMeta?.path ?? "/en/stores/:store_id()/general-journals/:general_journal_id()/general-journal-entries/:general_journal_entry_id()/edit",
    meta: editLSdgHLGZcUMeta || {},
    alias: editLSdgHLGZcUMeta?.alias || [],
    redirect: editLSdgHLGZcUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/[general_journal_entry_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexKG2WcntYtyMeta?.name ?? "stores-store_id-general-journals-general_journal_id-general-journal-entries-general_journal_entry_id___id",
    path: indexKG2WcntYtyMeta?.path ?? "/stores/:store_id()/general-journals/:general_journal_id()/general-journal-entries/:general_journal_entry_id()",
    meta: indexKG2WcntYtyMeta || {},
    alias: indexKG2WcntYtyMeta?.alias || [],
    redirect: indexKG2WcntYtyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/[general_journal_entry_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKG2WcntYtyMeta?.name ?? "stores-store_id-general-journals-general_journal_id-general-journal-entries-general_journal_entry_id___en",
    path: indexKG2WcntYtyMeta?.path ?? "/en/stores/:store_id()/general-journals/:general_journal_id()/general-journal-entries/:general_journal_entry_id()",
    meta: indexKG2WcntYtyMeta || {},
    alias: indexKG2WcntYtyMeta?.alias || [],
    redirect: indexKG2WcntYtyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/[general_journal_entry_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createXUmUqyXFFqMeta?.name ?? "stores-store_id-general-journals-general_journal_id-general-journal-entries-create___id",
    path: createXUmUqyXFFqMeta?.path ?? "/stores/:store_id()/general-journals/:general_journal_id()/general-journal-entries/create",
    meta: createXUmUqyXFFqMeta || {},
    alias: createXUmUqyXFFqMeta?.alias || [],
    redirect: createXUmUqyXFFqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/create.vue").then(m => m.default || m)
  },
  {
    name: createXUmUqyXFFqMeta?.name ?? "stores-store_id-general-journals-general_journal_id-general-journal-entries-create___en",
    path: createXUmUqyXFFqMeta?.path ?? "/en/stores/:store_id()/general-journals/:general_journal_id()/general-journal-entries/create",
    meta: createXUmUqyXFFqMeta || {},
    alias: createXUmUqyXFFqMeta?.alias || [],
    redirect: createXUmUqyXFFqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/create.vue").then(m => m.default || m)
  },
  {
    name: indexBNTkyPE7rkMeta?.name ?? "stores-store_id-general-journals-general_journal_id-general-journal-entries___id",
    path: indexBNTkyPE7rkMeta?.path ?? "/stores/:store_id()/general-journals/:general_journal_id()/general-journal-entries",
    meta: indexBNTkyPE7rkMeta || {},
    alias: indexBNTkyPE7rkMeta?.alias || [],
    redirect: indexBNTkyPE7rkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/index.vue").then(m => m.default || m)
  },
  {
    name: indexBNTkyPE7rkMeta?.name ?? "stores-store_id-general-journals-general_journal_id-general-journal-entries___en",
    path: indexBNTkyPE7rkMeta?.path ?? "/en/stores/:store_id()/general-journals/:general_journal_id()/general-journal-entries",
    meta: indexBNTkyPE7rkMeta || {},
    alias: indexBNTkyPE7rkMeta?.alias || [],
    redirect: indexBNTkyPE7rkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/general-journal-entries/index.vue").then(m => m.default || m)
  },
  {
    name: indexk5k1lmYXUTMeta?.name ?? "stores-store_id-general-journals-general_journal_id___id",
    path: indexk5k1lmYXUTMeta?.path ?? "/stores/:store_id()/general-journals/:general_journal_id()",
    meta: indexk5k1lmYXUTMeta || {},
    alias: indexk5k1lmYXUTMeta?.alias || [],
    redirect: indexk5k1lmYXUTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexk5k1lmYXUTMeta?.name ?? "stores-store_id-general-journals-general_journal_id___en",
    path: indexk5k1lmYXUTMeta?.path ?? "/en/stores/:store_id()/general-journals/:general_journal_id()",
    meta: indexk5k1lmYXUTMeta || {},
    alias: indexk5k1lmYXUTMeta?.alias || [],
    redirect: indexk5k1lmYXUTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/[general_journal_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createCY9ervPg5jMeta?.name ?? "stores-store_id-general-journals-create___id",
    path: createCY9ervPg5jMeta?.path ?? "/stores/:store_id()/general-journals/create",
    meta: createCY9ervPg5jMeta || {},
    alias: createCY9ervPg5jMeta?.alias || [],
    redirect: createCY9ervPg5jMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/create.vue").then(m => m.default || m)
  },
  {
    name: createCY9ervPg5jMeta?.name ?? "stores-store_id-general-journals-create___en",
    path: createCY9ervPg5jMeta?.path ?? "/en/stores/:store_id()/general-journals/create",
    meta: createCY9ervPg5jMeta || {},
    alias: createCY9ervPg5jMeta?.alias || [],
    redirect: createCY9ervPg5jMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/create.vue").then(m => m.default || m)
  },
  {
    name: index5qKWU2pv7YMeta?.name ?? "stores-store_id-general-journals___id",
    path: index5qKWU2pv7YMeta?.path ?? "/stores/:store_id()/general-journals",
    meta: index5qKWU2pv7YMeta || {},
    alias: index5qKWU2pv7YMeta?.alias || [],
    redirect: index5qKWU2pv7YMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/index.vue").then(m => m.default || m)
  },
  {
    name: index5qKWU2pv7YMeta?.name ?? "stores-store_id-general-journals___en",
    path: index5qKWU2pv7YMeta?.path ?? "/en/stores/:store_id()/general-journals",
    meta: index5qKWU2pv7YMeta || {},
    alias: index5qKWU2pv7YMeta?.alias || [],
    redirect: index5qKWU2pv7YMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/general-journals/index.vue").then(m => m.default || m)
  },
  {
    name: editbhBqmhbz3DMeta?.name ?? "stores-store_id-items-item_id-base-prices-base_price_id-edit___id",
    path: editbhBqmhbz3DMeta?.path ?? "/stores/:store_id()/items/:item_id()/base-prices/:base_price_id()/edit",
    meta: editbhBqmhbz3DMeta || {},
    alias: editbhBqmhbz3DMeta?.alias || [],
    redirect: editbhBqmhbz3DMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editbhBqmhbz3DMeta?.name ?? "stores-store_id-items-item_id-base-prices-base_price_id-edit___en",
    path: editbhBqmhbz3DMeta?.path ?? "/en/stores/:store_id()/items/:item_id()/base-prices/:base_price_id()/edit",
    meta: editbhBqmhbz3DMeta || {},
    alias: editbhBqmhbz3DMeta?.alias || [],
    redirect: editbhBqmhbz3DMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexEhTvd7D7xoMeta?.name ?? "stores-store_id-items-item_id-base-prices-base_price_id___id",
    path: indexEhTvd7D7xoMeta?.path ?? "/stores/:store_id()/items/:item_id()/base-prices/:base_price_id()",
    meta: indexEhTvd7D7xoMeta || {},
    alias: indexEhTvd7D7xoMeta?.alias || [],
    redirect: indexEhTvd7D7xoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEhTvd7D7xoMeta?.name ?? "stores-store_id-items-item_id-base-prices-base_price_id___en",
    path: indexEhTvd7D7xoMeta?.path ?? "/en/stores/:store_id()/items/:item_id()/base-prices/:base_price_id()",
    meta: indexEhTvd7D7xoMeta || {},
    alias: indexEhTvd7D7xoMeta?.alias || [],
    redirect: indexEhTvd7D7xoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/index.vue").then(m => m.default || m)
  },
  {
    name: editW8pUou6Vi5Meta?.name ?? "stores-store_id-items-item_id-base-prices-base_price_id-sell-prices-sell_price_id-edit___id",
    path: editW8pUou6Vi5Meta?.path ?? "/stores/:store_id()/items/:item_id()/base-prices/:base_price_id()/sell-prices/:sell_price_id()/edit",
    meta: editW8pUou6Vi5Meta || {},
    alias: editW8pUou6Vi5Meta?.alias || [],
    redirect: editW8pUou6Vi5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/sell-prices/[sell_price_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editW8pUou6Vi5Meta?.name ?? "stores-store_id-items-item_id-base-prices-base_price_id-sell-prices-sell_price_id-edit___en",
    path: editW8pUou6Vi5Meta?.path ?? "/en/stores/:store_id()/items/:item_id()/base-prices/:base_price_id()/sell-prices/:sell_price_id()/edit",
    meta: editW8pUou6Vi5Meta || {},
    alias: editW8pUou6Vi5Meta?.alias || [],
    redirect: editW8pUou6Vi5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/sell-prices/[sell_price_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexCBc0rKZwZqMeta?.name ?? "stores-store_id-items-item_id-base-prices-base_price_id-sell-prices-sell_price_id___id",
    path: indexCBc0rKZwZqMeta?.path ?? "/stores/:store_id()/items/:item_id()/base-prices/:base_price_id()/sell-prices/:sell_price_id()",
    meta: indexCBc0rKZwZqMeta || {},
    alias: indexCBc0rKZwZqMeta?.alias || [],
    redirect: indexCBc0rKZwZqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/sell-prices/[sell_price_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexCBc0rKZwZqMeta?.name ?? "stores-store_id-items-item_id-base-prices-base_price_id-sell-prices-sell_price_id___en",
    path: indexCBc0rKZwZqMeta?.path ?? "/en/stores/:store_id()/items/:item_id()/base-prices/:base_price_id()/sell-prices/:sell_price_id()",
    meta: indexCBc0rKZwZqMeta || {},
    alias: indexCBc0rKZwZqMeta?.alias || [],
    redirect: indexCBc0rKZwZqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/sell-prices/[sell_price_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createDClvDmY7GIMeta?.name ?? "stores-store_id-items-item_id-base-prices-base_price_id-sell-prices-create___id",
    path: createDClvDmY7GIMeta?.path ?? "/stores/:store_id()/items/:item_id()/base-prices/:base_price_id()/sell-prices/create",
    meta: createDClvDmY7GIMeta || {},
    alias: createDClvDmY7GIMeta?.alias || [],
    redirect: createDClvDmY7GIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/sell-prices/create.vue").then(m => m.default || m)
  },
  {
    name: createDClvDmY7GIMeta?.name ?? "stores-store_id-items-item_id-base-prices-base_price_id-sell-prices-create___en",
    path: createDClvDmY7GIMeta?.path ?? "/en/stores/:store_id()/items/:item_id()/base-prices/:base_price_id()/sell-prices/create",
    meta: createDClvDmY7GIMeta || {},
    alias: createDClvDmY7GIMeta?.alias || [],
    redirect: createDClvDmY7GIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/[base_price_id]/sell-prices/create.vue").then(m => m.default || m)
  },
  {
    name: create6mnty6iyfCMeta?.name ?? "stores-store_id-items-item_id-base-prices-create___id",
    path: create6mnty6iyfCMeta?.path ?? "/stores/:store_id()/items/:item_id()/base-prices/create",
    meta: create6mnty6iyfCMeta || {},
    alias: create6mnty6iyfCMeta?.alias || [],
    redirect: create6mnty6iyfCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/create.vue").then(m => m.default || m)
  },
  {
    name: create6mnty6iyfCMeta?.name ?? "stores-store_id-items-item_id-base-prices-create___en",
    path: create6mnty6iyfCMeta?.path ?? "/en/stores/:store_id()/items/:item_id()/base-prices/create",
    meta: create6mnty6iyfCMeta || {},
    alias: create6mnty6iyfCMeta?.alias || [],
    redirect: create6mnty6iyfCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/base-prices/create.vue").then(m => m.default || m)
  },
  {
    name: editImpeCjkHDsMeta?.name ?? "stores-store_id-items-item_id-edit___id",
    path: editImpeCjkHDsMeta?.path ?? "/stores/:store_id()/items/:item_id()/edit",
    meta: editImpeCjkHDsMeta || {},
    alias: editImpeCjkHDsMeta?.alias || [],
    redirect: editImpeCjkHDsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editImpeCjkHDsMeta?.name ?? "stores-store_id-items-item_id-edit___en",
    path: editImpeCjkHDsMeta?.path ?? "/en/stores/:store_id()/items/:item_id()/edit",
    meta: editImpeCjkHDsMeta || {},
    alias: editImpeCjkHDsMeta?.alias || [],
    redirect: editImpeCjkHDsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexOlfqh6C2URMeta?.name ?? "stores-store_id-items-item_id___id",
    path: indexOlfqh6C2URMeta?.path ?? "/stores/:store_id()/items/:item_id()",
    meta: indexOlfqh6C2URMeta || {},
    alias: indexOlfqh6C2URMeta?.alias || [],
    redirect: indexOlfqh6C2URMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOlfqh6C2URMeta?.name ?? "stores-store_id-items-item_id___en",
    path: indexOlfqh6C2URMeta?.path ?? "/en/stores/:store_id()/items/:item_id()",
    meta: indexOlfqh6C2URMeta || {},
    alias: indexOlfqh6C2URMeta?.alias || [],
    redirect: indexOlfqh6C2URMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/index.vue").then(m => m.default || m)
  },
  {
    name: editx8cXggViwGMeta?.name ?? "stores-store_id-items-item_id-units-unit_id-edit___id",
    path: editx8cXggViwGMeta?.path ?? "/stores/:store_id()/items/:item_id()/units/:unit_id()/edit",
    meta: editx8cXggViwGMeta || {},
    alias: editx8cXggViwGMeta?.alias || [],
    redirect: editx8cXggViwGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/units/[unit_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editx8cXggViwGMeta?.name ?? "stores-store_id-items-item_id-units-unit_id-edit___en",
    path: editx8cXggViwGMeta?.path ?? "/en/stores/:store_id()/items/:item_id()/units/:unit_id()/edit",
    meta: editx8cXggViwGMeta || {},
    alias: editx8cXggViwGMeta?.alias || [],
    redirect: editx8cXggViwGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/units/[unit_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexezoe0qWHL6Meta?.name ?? "stores-store_id-items-item_id-units-unit_id___id",
    path: indexezoe0qWHL6Meta?.path ?? "/stores/:store_id()/items/:item_id()/units/:unit_id()",
    meta: indexezoe0qWHL6Meta || {},
    alias: indexezoe0qWHL6Meta?.alias || [],
    redirect: indexezoe0qWHL6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/units/[unit_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexezoe0qWHL6Meta?.name ?? "stores-store_id-items-item_id-units-unit_id___en",
    path: indexezoe0qWHL6Meta?.path ?? "/en/stores/:store_id()/items/:item_id()/units/:unit_id()",
    meta: indexezoe0qWHL6Meta || {},
    alias: indexezoe0qWHL6Meta?.alias || [],
    redirect: indexezoe0qWHL6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/units/[unit_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createJpKnsLuwoeMeta?.name ?? "stores-store_id-items-item_id-units-create___id",
    path: createJpKnsLuwoeMeta?.path ?? "/stores/:store_id()/items/:item_id()/units/create",
    meta: createJpKnsLuwoeMeta || {},
    alias: createJpKnsLuwoeMeta?.alias || [],
    redirect: createJpKnsLuwoeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/units/create.vue").then(m => m.default || m)
  },
  {
    name: createJpKnsLuwoeMeta?.name ?? "stores-store_id-items-item_id-units-create___en",
    path: createJpKnsLuwoeMeta?.path ?? "/en/stores/:store_id()/items/:item_id()/units/create",
    meta: createJpKnsLuwoeMeta || {},
    alias: createJpKnsLuwoeMeta?.alias || [],
    redirect: createJpKnsLuwoeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/[item_id]/units/create.vue").then(m => m.default || m)
  },
  {
    name: creategTe8U2gMR4Meta?.name ?? "stores-store_id-items-create___id",
    path: creategTe8U2gMR4Meta?.path ?? "/stores/:store_id()/items/create",
    meta: creategTe8U2gMR4Meta || {},
    alias: creategTe8U2gMR4Meta?.alias || [],
    redirect: creategTe8U2gMR4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/create.vue").then(m => m.default || m)
  },
  {
    name: creategTe8U2gMR4Meta?.name ?? "stores-store_id-items-create___en",
    path: creategTe8U2gMR4Meta?.path ?? "/en/stores/:store_id()/items/create",
    meta: creategTe8U2gMR4Meta || {},
    alias: creategTe8U2gMR4Meta?.alias || [],
    redirect: creategTe8U2gMR4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/create.vue").then(m => m.default || m)
  },
  {
    name: createp3bIoXa1nZMeta?.name ?? "stores-store_id-items-forms-create___id",
    path: createp3bIoXa1nZMeta?.path ?? "/stores/:store_id()/items/forms/create",
    meta: createp3bIoXa1nZMeta || {},
    alias: createp3bIoXa1nZMeta?.alias || [],
    redirect: createp3bIoXa1nZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/forms/create.vue").then(m => m.default || m)
  },
  {
    name: createp3bIoXa1nZMeta?.name ?? "stores-store_id-items-forms-create___en",
    path: createp3bIoXa1nZMeta?.path ?? "/en/stores/:store_id()/items/forms/create",
    meta: createp3bIoXa1nZMeta || {},
    alias: createp3bIoXa1nZMeta?.alias || [],
    redirect: createp3bIoXa1nZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/forms/create.vue").then(m => m.default || m)
  },
  {
    name: indexogrFwB602NMeta?.name ?? "stores-store_id-items___id",
    path: indexogrFwB602NMeta?.path ?? "/stores/:store_id()/items",
    meta: indexogrFwB602NMeta || {},
    alias: indexogrFwB602NMeta?.alias || [],
    redirect: indexogrFwB602NMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/index.vue").then(m => m.default || m)
  },
  {
    name: indexogrFwB602NMeta?.name ?? "stores-store_id-items___en",
    path: indexogrFwB602NMeta?.path ?? "/en/stores/:store_id()/items",
    meta: indexogrFwB602NMeta || {},
    alias: indexogrFwB602NMeta?.alias || [],
    redirect: indexogrFwB602NMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/items/index.vue").then(m => m.default || m)
  },
  {
    name: editiGHPlzXeubMeta?.name ?? "stores-store_id-price-categories-price_category_id-edit___id",
    path: editiGHPlzXeubMeta?.path ?? "/stores/:store_id()/price-categories/:price_category_id()/edit",
    meta: editiGHPlzXeubMeta || {},
    alias: editiGHPlzXeubMeta?.alias || [],
    redirect: editiGHPlzXeubMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/[price_category_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editiGHPlzXeubMeta?.name ?? "stores-store_id-price-categories-price_category_id-edit___en",
    path: editiGHPlzXeubMeta?.path ?? "/en/stores/:store_id()/price-categories/:price_category_id()/edit",
    meta: editiGHPlzXeubMeta || {},
    alias: editiGHPlzXeubMeta?.alias || [],
    redirect: editiGHPlzXeubMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/[price_category_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexyqyU9zcgiLMeta?.name ?? "stores-store_id-price-categories-price_category_id___id",
    path: indexyqyU9zcgiLMeta?.path ?? "/stores/:store_id()/price-categories/:price_category_id()",
    meta: indexyqyU9zcgiLMeta || {},
    alias: indexyqyU9zcgiLMeta?.alias || [],
    redirect: indexyqyU9zcgiLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/[price_category_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyqyU9zcgiLMeta?.name ?? "stores-store_id-price-categories-price_category_id___en",
    path: indexyqyU9zcgiLMeta?.path ?? "/en/stores/:store_id()/price-categories/:price_category_id()",
    meta: indexyqyU9zcgiLMeta || {},
    alias: indexyqyU9zcgiLMeta?.alias || [],
    redirect: indexyqyU9zcgiLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/[price_category_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createpbvmlR4HsjMeta?.name ?? "stores-store_id-price-categories-create___id",
    path: createpbvmlR4HsjMeta?.path ?? "/stores/:store_id()/price-categories/create",
    meta: createpbvmlR4HsjMeta || {},
    alias: createpbvmlR4HsjMeta?.alias || [],
    redirect: createpbvmlR4HsjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/create.vue").then(m => m.default || m)
  },
  {
    name: createpbvmlR4HsjMeta?.name ?? "stores-store_id-price-categories-create___en",
    path: createpbvmlR4HsjMeta?.path ?? "/en/stores/:store_id()/price-categories/create",
    meta: createpbvmlR4HsjMeta || {},
    alias: createpbvmlR4HsjMeta?.alias || [],
    redirect: createpbvmlR4HsjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/create.vue").then(m => m.default || m)
  },
  {
    name: indexQIUSkF80nxMeta?.name ?? "stores-store_id-price-categories___id",
    path: indexQIUSkF80nxMeta?.path ?? "/stores/:store_id()/price-categories",
    meta: indexQIUSkF80nxMeta || {},
    alias: indexQIUSkF80nxMeta?.alias || [],
    redirect: indexQIUSkF80nxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexQIUSkF80nxMeta?.name ?? "stores-store_id-price-categories___en",
    path: indexQIUSkF80nxMeta?.path ?? "/en/stores/:store_id()/price-categories",
    meta: indexQIUSkF80nxMeta || {},
    alias: indexQIUSkF80nxMeta?.alias || [],
    redirect: indexQIUSkF80nxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-categories/index.vue").then(m => m.default || m)
  },
  {
    name: index0frHZ9Wis6Meta?.name ?? "stores-store_id-price-checks___id",
    path: index0frHZ9Wis6Meta?.path ?? "/stores/:store_id()/price-checks",
    meta: index0frHZ9Wis6Meta || {},
    alias: index0frHZ9Wis6Meta?.alias || [],
    redirect: index0frHZ9Wis6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-checks/index.vue").then(m => m.default || m)
  },
  {
    name: index0frHZ9Wis6Meta?.name ?? "stores-store_id-price-checks___en",
    path: index0frHZ9Wis6Meta?.path ?? "/en/stores/:store_id()/price-checks",
    meta: index0frHZ9Wis6Meta || {},
    alias: index0frHZ9Wis6Meta?.alias || [],
    redirect: index0frHZ9Wis6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/price-checks/index.vue").then(m => m.default || m)
  },
  {
    name: editBqp62dIadnMeta?.name ?? "stores-store_id-print-barcodes-print_barcodes_id-edit___id",
    path: editBqp62dIadnMeta?.path ?? "/stores/:store_id()/print-barcodes/:print_barcodes_id()/edit",
    meta: editBqp62dIadnMeta || {},
    alias: editBqp62dIadnMeta?.alias || [],
    redirect: editBqp62dIadnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/[print_barcodes_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editBqp62dIadnMeta?.name ?? "stores-store_id-print-barcodes-print_barcodes_id-edit___en",
    path: editBqp62dIadnMeta?.path ?? "/en/stores/:store_id()/print-barcodes/:print_barcodes_id()/edit",
    meta: editBqp62dIadnMeta || {},
    alias: editBqp62dIadnMeta?.alias || [],
    redirect: editBqp62dIadnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/[print_barcodes_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexubIhozJMHuMeta?.name ?? "stores-store_id-print-barcodes-print_barcodes_id___id",
    path: indexubIhozJMHuMeta?.path ?? "/stores/:store_id()/print-barcodes/:print_barcodes_id()",
    meta: indexubIhozJMHuMeta || {},
    alias: indexubIhozJMHuMeta?.alias || [],
    redirect: indexubIhozJMHuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/[print_barcodes_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexubIhozJMHuMeta?.name ?? "stores-store_id-print-barcodes-print_barcodes_id___en",
    path: indexubIhozJMHuMeta?.path ?? "/en/stores/:store_id()/print-barcodes/:print_barcodes_id()",
    meta: indexubIhozJMHuMeta || {},
    alias: indexubIhozJMHuMeta?.alias || [],
    redirect: indexubIhozJMHuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/[print_barcodes_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createwPHTEjj2lyMeta?.name ?? "stores-store_id-print-barcodes-create___id",
    path: createwPHTEjj2lyMeta?.path ?? "/stores/:store_id()/print-barcodes/create",
    meta: createwPHTEjj2lyMeta || {},
    alias: createwPHTEjj2lyMeta?.alias || [],
    redirect: createwPHTEjj2lyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/create.vue").then(m => m.default || m)
  },
  {
    name: createwPHTEjj2lyMeta?.name ?? "stores-store_id-print-barcodes-create___en",
    path: createwPHTEjj2lyMeta?.path ?? "/en/stores/:store_id()/print-barcodes/create",
    meta: createwPHTEjj2lyMeta || {},
    alias: createwPHTEjj2lyMeta?.alias || [],
    redirect: createwPHTEjj2lyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/create.vue").then(m => m.default || m)
  },
  {
    name: indexMcdpl5dwHJMeta?.name ?? "stores-store_id-print-barcodes___id",
    path: indexMcdpl5dwHJMeta?.path ?? "/stores/:store_id()/print-barcodes",
    meta: indexMcdpl5dwHJMeta || {},
    alias: indexMcdpl5dwHJMeta?.alias || [],
    redirect: indexMcdpl5dwHJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/index.vue").then(m => m.default || m)
  },
  {
    name: indexMcdpl5dwHJMeta?.name ?? "stores-store_id-print-barcodes___en",
    path: indexMcdpl5dwHJMeta?.path ?? "/en/stores/:store_id()/print-barcodes",
    meta: indexMcdpl5dwHJMeta || {},
    alias: indexMcdpl5dwHJMeta?.alias || [],
    redirect: indexMcdpl5dwHJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/print-barcodes/index.vue").then(m => m.default || m)
  },
  {
    name: editJvRF5n9wYiMeta?.name ?? "stores-store_id-printer-settings-printer_settings_id-edit___id",
    path: editJvRF5n9wYiMeta?.path ?? "/stores/:store_id()/printer-settings/:printer_settings_id()/edit",
    meta: editJvRF5n9wYiMeta || {},
    alias: editJvRF5n9wYiMeta?.alias || [],
    redirect: editJvRF5n9wYiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/[printer_settings_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editJvRF5n9wYiMeta?.name ?? "stores-store_id-printer-settings-printer_settings_id-edit___en",
    path: editJvRF5n9wYiMeta?.path ?? "/en/stores/:store_id()/printer-settings/:printer_settings_id()/edit",
    meta: editJvRF5n9wYiMeta || {},
    alias: editJvRF5n9wYiMeta?.alias || [],
    redirect: editJvRF5n9wYiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/[printer_settings_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQCcEH1OZ8qMeta?.name ?? "stores-store_id-printer-settings-printer_settings_id___id",
    path: indexQCcEH1OZ8qMeta?.path ?? "/stores/:store_id()/printer-settings/:printer_settings_id()",
    meta: indexQCcEH1OZ8qMeta || {},
    alias: indexQCcEH1OZ8qMeta?.alias || [],
    redirect: indexQCcEH1OZ8qMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/[printer_settings_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQCcEH1OZ8qMeta?.name ?? "stores-store_id-printer-settings-printer_settings_id___en",
    path: indexQCcEH1OZ8qMeta?.path ?? "/en/stores/:store_id()/printer-settings/:printer_settings_id()",
    meta: indexQCcEH1OZ8qMeta || {},
    alias: indexQCcEH1OZ8qMeta?.alias || [],
    redirect: indexQCcEH1OZ8qMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/[printer_settings_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createISONEjxoKwMeta?.name ?? "stores-store_id-printer-settings-create___id",
    path: createISONEjxoKwMeta?.path ?? "/stores/:store_id()/printer-settings/create",
    meta: createISONEjxoKwMeta || {},
    alias: createISONEjxoKwMeta?.alias || [],
    redirect: createISONEjxoKwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/create.vue").then(m => m.default || m)
  },
  {
    name: createISONEjxoKwMeta?.name ?? "stores-store_id-printer-settings-create___en",
    path: createISONEjxoKwMeta?.path ?? "/en/stores/:store_id()/printer-settings/create",
    meta: createISONEjxoKwMeta || {},
    alias: createISONEjxoKwMeta?.alias || [],
    redirect: createISONEjxoKwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/create.vue").then(m => m.default || m)
  },
  {
    name: indexCYyWl4Vj4NMeta?.name ?? "stores-store_id-printer-settings___id",
    path: indexCYyWl4Vj4NMeta?.path ?? "/stores/:store_id()/printer-settings",
    meta: indexCYyWl4Vj4NMeta || {},
    alias: indexCYyWl4Vj4NMeta?.alias || [],
    redirect: indexCYyWl4Vj4NMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexCYyWl4Vj4NMeta?.name ?? "stores-store_id-printer-settings___en",
    path: indexCYyWl4Vj4NMeta?.path ?? "/en/stores/:store_id()/printer-settings",
    meta: indexCYyWl4Vj4NMeta || {},
    alias: indexCYyWl4Vj4NMeta?.alias || [],
    redirect: indexCYyWl4Vj4NMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/printer-settings/index.vue").then(m => m.default || m)
  },
  {
    name: profilepFva3LaRXQMeta?.name ?? "stores-store_id-profile___id",
    path: profilepFva3LaRXQMeta?.path ?? "/stores/:store_id()/profile",
    meta: profilepFva3LaRXQMeta || {},
    alias: profilepFva3LaRXQMeta?.alias || [],
    redirect: profilepFva3LaRXQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/profile.vue").then(m => m.default || m)
  },
  {
    name: profilepFva3LaRXQMeta?.name ?? "stores-store_id-profile___en",
    path: profilepFva3LaRXQMeta?.path ?? "/en/stores/:store_id()/profile",
    meta: profilepFva3LaRXQMeta || {},
    alias: profilepFva3LaRXQMeta?.alias || [],
    redirect: profilepFva3LaRXQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/profile.vue").then(m => m.default || m)
  },
  {
    name: editqLjwIivy37Meta?.name ?? "stores-store_id-purchase-receivings-purchase_id-edit___id",
    path: editqLjwIivy37Meta?.path ?? "/stores/:store_id()/purchase-receivings/:purchase_id()/edit",
    meta: editqLjwIivy37Meta || {},
    alias: editqLjwIivy37Meta?.alias || [],
    redirect: editqLjwIivy37Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editqLjwIivy37Meta?.name ?? "stores-store_id-purchase-receivings-purchase_id-edit___en",
    path: editqLjwIivy37Meta?.path ?? "/en/stores/:store_id()/purchase-receivings/:purchase_id()/edit",
    meta: editqLjwIivy37Meta || {},
    alias: editqLjwIivy37Meta?.alias || [],
    redirect: editqLjwIivy37Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexkLJLcnjRjgMeta?.name ?? "stores-store_id-purchase-receivings-purchase_id___id",
    path: indexkLJLcnjRjgMeta?.path ?? "/stores/:store_id()/purchase-receivings/:purchase_id()",
    meta: indexkLJLcnjRjgMeta || {},
    alias: indexkLJLcnjRjgMeta?.alias || [],
    redirect: indexkLJLcnjRjgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkLJLcnjRjgMeta?.name ?? "stores-store_id-purchase-receivings-purchase_id___en",
    path: indexkLJLcnjRjgMeta?.path ?? "/en/stores/:store_id()/purchase-receivings/:purchase_id()",
    meta: indexkLJLcnjRjgMeta || {},
    alias: indexkLJLcnjRjgMeta?.alias || [],
    redirect: indexkLJLcnjRjgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/index.vue").then(m => m.default || m)
  },
  {
    name: editqOQbBldc2rMeta?.name ?? "stores-store_id-purchase-receivings-purchase_id-returns-purchase_return_id-edit___id",
    path: editqOQbBldc2rMeta?.path ?? "/stores/:store_id()/purchase-receivings/:purchase_id()/returns/:purchase_return_id()/edit",
    meta: editqOQbBldc2rMeta || {},
    alias: editqOQbBldc2rMeta?.alias || [],
    redirect: editqOQbBldc2rMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/returns/[purchase_return_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editqOQbBldc2rMeta?.name ?? "stores-store_id-purchase-receivings-purchase_id-returns-purchase_return_id-edit___en",
    path: editqOQbBldc2rMeta?.path ?? "/en/stores/:store_id()/purchase-receivings/:purchase_id()/returns/:purchase_return_id()/edit",
    meta: editqOQbBldc2rMeta || {},
    alias: editqOQbBldc2rMeta?.alias || [],
    redirect: editqOQbBldc2rMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/returns/[purchase_return_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexs1Chtj7MneMeta?.name ?? "stores-store_id-purchase-receivings-purchase_id-returns-purchase_return_id___id",
    path: indexs1Chtj7MneMeta?.path ?? "/stores/:store_id()/purchase-receivings/:purchase_id()/returns/:purchase_return_id()",
    meta: indexs1Chtj7MneMeta || {},
    alias: indexs1Chtj7MneMeta?.alias || [],
    redirect: indexs1Chtj7MneMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/returns/[purchase_return_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexs1Chtj7MneMeta?.name ?? "stores-store_id-purchase-receivings-purchase_id-returns-purchase_return_id___en",
    path: indexs1Chtj7MneMeta?.path ?? "/en/stores/:store_id()/purchase-receivings/:purchase_id()/returns/:purchase_return_id()",
    meta: indexs1Chtj7MneMeta || {},
    alias: indexs1Chtj7MneMeta?.alias || [],
    redirect: indexs1Chtj7MneMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/returns/[purchase_return_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createTYmvqD4UpqMeta?.name ?? "stores-store_id-purchase-receivings-purchase_id-returns-create___id",
    path: createTYmvqD4UpqMeta?.path ?? "/stores/:store_id()/purchase-receivings/:purchase_id()/returns/create",
    meta: createTYmvqD4UpqMeta || {},
    alias: createTYmvqD4UpqMeta?.alias || [],
    redirect: createTYmvqD4UpqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/returns/create.vue").then(m => m.default || m)
  },
  {
    name: createTYmvqD4UpqMeta?.name ?? "stores-store_id-purchase-receivings-purchase_id-returns-create___en",
    path: createTYmvqD4UpqMeta?.path ?? "/en/stores/:store_id()/purchase-receivings/:purchase_id()/returns/create",
    meta: createTYmvqD4UpqMeta || {},
    alias: createTYmvqD4UpqMeta?.alias || [],
    redirect: createTYmvqD4UpqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/[purchase_id]/returns/create.vue").then(m => m.default || m)
  },
  {
    name: createhI8C4HWDiFMeta?.name ?? "stores-store_id-purchase-receivings-create___id",
    path: createhI8C4HWDiFMeta?.path ?? "/stores/:store_id()/purchase-receivings/create",
    meta: createhI8C4HWDiFMeta || {},
    alias: createhI8C4HWDiFMeta?.alias || [],
    redirect: createhI8C4HWDiFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/create.vue").then(m => m.default || m)
  },
  {
    name: createhI8C4HWDiFMeta?.name ?? "stores-store_id-purchase-receivings-create___en",
    path: createhI8C4HWDiFMeta?.path ?? "/en/stores/:store_id()/purchase-receivings/create",
    meta: createhI8C4HWDiFMeta || {},
    alias: createhI8C4HWDiFMeta?.alias || [],
    redirect: createhI8C4HWDiFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/create.vue").then(m => m.default || m)
  },
  {
    name: indexOZYmxrHHX3Meta?.name ?? "stores-store_id-purchase-receivings___id",
    path: indexOZYmxrHHX3Meta?.path ?? "/stores/:store_id()/purchase-receivings",
    meta: indexOZYmxrHHX3Meta || {},
    alias: indexOZYmxrHHX3Meta?.alias || [],
    redirect: indexOZYmxrHHX3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/index.vue").then(m => m.default || m)
  },
  {
    name: indexOZYmxrHHX3Meta?.name ?? "stores-store_id-purchase-receivings___en",
    path: indexOZYmxrHHX3Meta?.path ?? "/en/stores/:store_id()/purchase-receivings",
    meta: indexOZYmxrHHX3Meta || {},
    alias: indexOZYmxrHHX3Meta?.alias || [],
    redirect: indexOZYmxrHHX3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-receivings/index.vue").then(m => m.default || m)
  },
  {
    name: indexx2LnfLfhRjMeta?.name ?? "stores-store_id-purchase-returns___id",
    path: indexx2LnfLfhRjMeta?.path ?? "/stores/:store_id()/purchase-returns",
    meta: indexx2LnfLfhRjMeta || {},
    alias: indexx2LnfLfhRjMeta?.alias || [],
    redirect: indexx2LnfLfhRjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-returns/index.vue").then(m => m.default || m)
  },
  {
    name: indexx2LnfLfhRjMeta?.name ?? "stores-store_id-purchase-returns___en",
    path: indexx2LnfLfhRjMeta?.path ?? "/en/stores/:store_id()/purchase-returns",
    meta: indexx2LnfLfhRjMeta || {},
    alias: indexx2LnfLfhRjMeta?.alias || [],
    redirect: indexx2LnfLfhRjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchase-returns/index.vue").then(m => m.default || m)
  },
  {
    name: editLRQNKjwccOMeta?.name ?? "stores-store_id-purchases-purchase_id-edit___id",
    path: editLRQNKjwccOMeta?.path ?? "/stores/:store_id()/purchases/:purchase_id()/edit",
    meta: editLRQNKjwccOMeta || {},
    alias: editLRQNKjwccOMeta?.alias || [],
    redirect: editLRQNKjwccOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLRQNKjwccOMeta?.name ?? "stores-store_id-purchases-purchase_id-edit___en",
    path: editLRQNKjwccOMeta?.path ?? "/en/stores/:store_id()/purchases/:purchase_id()/edit",
    meta: editLRQNKjwccOMeta || {},
    alias: editLRQNKjwccOMeta?.alias || [],
    redirect: editLRQNKjwccOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexuUyl09SO9iMeta?.name ?? "stores-store_id-purchases-purchase_id___id",
    path: indexuUyl09SO9iMeta?.path ?? "/stores/:store_id()/purchases/:purchase_id()",
    meta: indexuUyl09SO9iMeta || {},
    alias: indexuUyl09SO9iMeta?.alias || [],
    redirect: indexuUyl09SO9iMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuUyl09SO9iMeta?.name ?? "stores-store_id-purchases-purchase_id___en",
    path: indexuUyl09SO9iMeta?.path ?? "/en/stores/:store_id()/purchases/:purchase_id()",
    meta: indexuUyl09SO9iMeta || {},
    alias: indexuUyl09SO9iMeta?.alias || [],
    redirect: indexuUyl09SO9iMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/index.vue").then(m => m.default || m)
  },
  {
    name: editalxpEPnnoJMeta?.name ?? "stores-store_id-purchases-purchase_id-returns-purchase_return_id-edit___id",
    path: editalxpEPnnoJMeta?.path ?? "/stores/:store_id()/purchases/:purchase_id()/returns/:purchase_return_id()/edit",
    meta: editalxpEPnnoJMeta || {},
    alias: editalxpEPnnoJMeta?.alias || [],
    redirect: editalxpEPnnoJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/returns/[purchase_return_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editalxpEPnnoJMeta?.name ?? "stores-store_id-purchases-purchase_id-returns-purchase_return_id-edit___en",
    path: editalxpEPnnoJMeta?.path ?? "/en/stores/:store_id()/purchases/:purchase_id()/returns/:purchase_return_id()/edit",
    meta: editalxpEPnnoJMeta || {},
    alias: editalxpEPnnoJMeta?.alias || [],
    redirect: editalxpEPnnoJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/returns/[purchase_return_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZv5uVRmps6Meta?.name ?? "stores-store_id-purchases-purchase_id-returns-purchase_return_id___id",
    path: indexZv5uVRmps6Meta?.path ?? "/stores/:store_id()/purchases/:purchase_id()/returns/:purchase_return_id()",
    meta: indexZv5uVRmps6Meta || {},
    alias: indexZv5uVRmps6Meta?.alias || [],
    redirect: indexZv5uVRmps6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/returns/[purchase_return_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZv5uVRmps6Meta?.name ?? "stores-store_id-purchases-purchase_id-returns-purchase_return_id___en",
    path: indexZv5uVRmps6Meta?.path ?? "/en/stores/:store_id()/purchases/:purchase_id()/returns/:purchase_return_id()",
    meta: indexZv5uVRmps6Meta || {},
    alias: indexZv5uVRmps6Meta?.alias || [],
    redirect: indexZv5uVRmps6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/returns/[purchase_return_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createmTboSJ1BcWMeta?.name ?? "stores-store_id-purchases-purchase_id-returns-create___id",
    path: createmTboSJ1BcWMeta?.path ?? "/stores/:store_id()/purchases/:purchase_id()/returns/create",
    meta: createmTboSJ1BcWMeta || {},
    alias: createmTboSJ1BcWMeta?.alias || [],
    redirect: createmTboSJ1BcWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/returns/create.vue").then(m => m.default || m)
  },
  {
    name: createmTboSJ1BcWMeta?.name ?? "stores-store_id-purchases-purchase_id-returns-create___en",
    path: createmTboSJ1BcWMeta?.path ?? "/en/stores/:store_id()/purchases/:purchase_id()/returns/create",
    meta: createmTboSJ1BcWMeta || {},
    alias: createmTboSJ1BcWMeta?.alias || [],
    redirect: createmTboSJ1BcWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/[purchase_id]/returns/create.vue").then(m => m.default || m)
  },
  {
    name: createw5TzFAkl9vMeta?.name ?? "stores-store_id-purchases-create___id",
    path: createw5TzFAkl9vMeta?.path ?? "/stores/:store_id()/purchases/create",
    meta: createw5TzFAkl9vMeta || {},
    alias: createw5TzFAkl9vMeta?.alias || [],
    redirect: createw5TzFAkl9vMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/create.vue").then(m => m.default || m)
  },
  {
    name: createw5TzFAkl9vMeta?.name ?? "stores-store_id-purchases-create___en",
    path: createw5TzFAkl9vMeta?.path ?? "/en/stores/:store_id()/purchases/create",
    meta: createw5TzFAkl9vMeta || {},
    alias: createw5TzFAkl9vMeta?.alias || [],
    redirect: createw5TzFAkl9vMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/create.vue").then(m => m.default || m)
  },
  {
    name: indexHk2MnVli04Meta?.name ?? "stores-store_id-purchases___id",
    path: indexHk2MnVli04Meta?.path ?? "/stores/:store_id()/purchases",
    meta: indexHk2MnVli04Meta || {},
    alias: indexHk2MnVli04Meta?.alias || [],
    redirect: indexHk2MnVli04Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/index.vue").then(m => m.default || m)
  },
  {
    name: indexHk2MnVli04Meta?.name ?? "stores-store_id-purchases___en",
    path: indexHk2MnVli04Meta?.path ?? "/en/stores/:store_id()/purchases",
    meta: indexHk2MnVli04Meta || {},
    alias: indexHk2MnVli04Meta?.alias || [],
    redirect: indexHk2MnVli04Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/purchases/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesXP9IgsCKwoMeta?.name ?? "stores-store_id-reports-purchases___id",
    path: purchasesXP9IgsCKwoMeta?.path ?? "/stores/:store_id()/reports/purchases",
    meta: purchasesXP9IgsCKwoMeta || {},
    alias: purchasesXP9IgsCKwoMeta?.alias || [],
    redirect: purchasesXP9IgsCKwoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/reports/purchases.vue").then(m => m.default || m)
  },
  {
    name: purchasesXP9IgsCKwoMeta?.name ?? "stores-store_id-reports-purchases___en",
    path: purchasesXP9IgsCKwoMeta?.path ?? "/en/stores/:store_id()/reports/purchases",
    meta: purchasesXP9IgsCKwoMeta || {},
    alias: purchasesXP9IgsCKwoMeta?.alias || [],
    redirect: purchasesXP9IgsCKwoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/reports/purchases.vue").then(m => m.default || m)
  },
  {
    name: salesCq7zUyguwGMeta?.name ?? "stores-store_id-reports-sales___id",
    path: salesCq7zUyguwGMeta?.path ?? "/stores/:store_id()/reports/sales",
    meta: salesCq7zUyguwGMeta || {},
    alias: salesCq7zUyguwGMeta?.alias || [],
    redirect: salesCq7zUyguwGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/reports/sales.vue").then(m => m.default || m)
  },
  {
    name: salesCq7zUyguwGMeta?.name ?? "stores-store_id-reports-sales___en",
    path: salesCq7zUyguwGMeta?.path ?? "/en/stores/:store_id()/reports/sales",
    meta: salesCq7zUyguwGMeta || {},
    alias: salesCq7zUyguwGMeta?.alias || [],
    redirect: salesCq7zUyguwGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/reports/sales.vue").then(m => m.default || m)
  },
  {
    name: stock_45alerts8GTW9S0ZPXMeta?.name ?? "stores-store_id-reports-stock-alerts___id",
    path: stock_45alerts8GTW9S0ZPXMeta?.path ?? "/stores/:store_id()/reports/stock-alerts",
    meta: stock_45alerts8GTW9S0ZPXMeta || {},
    alias: stock_45alerts8GTW9S0ZPXMeta?.alias || [],
    redirect: stock_45alerts8GTW9S0ZPXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/reports/stock-alerts.vue").then(m => m.default || m)
  },
  {
    name: stock_45alerts8GTW9S0ZPXMeta?.name ?? "stores-store_id-reports-stock-alerts___en",
    path: stock_45alerts8GTW9S0ZPXMeta?.path ?? "/en/stores/:store_id()/reports/stock-alerts",
    meta: stock_45alerts8GTW9S0ZPXMeta || {},
    alias: stock_45alerts8GTW9S0ZPXMeta?.alias || [],
    redirect: stock_45alerts8GTW9S0ZPXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/reports/stock-alerts.vue").then(m => m.default || m)
  },
  {
    name: index0idVGL8DkeMeta?.name ?? "stores-store_id-sale-returns___id",
    path: index0idVGL8DkeMeta?.path ?? "/stores/:store_id()/sale-returns",
    meta: index0idVGL8DkeMeta || {},
    alias: index0idVGL8DkeMeta?.alias || [],
    redirect: index0idVGL8DkeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sale-returns/index.vue").then(m => m.default || m)
  },
  {
    name: index0idVGL8DkeMeta?.name ?? "stores-store_id-sale-returns___en",
    path: index0idVGL8DkeMeta?.path ?? "/en/stores/:store_id()/sale-returns",
    meta: index0idVGL8DkeMeta || {},
    alias: index0idVGL8DkeMeta?.alias || [],
    redirect: index0idVGL8DkeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sale-returns/index.vue").then(m => m.default || m)
  },
  {
    name: edit0q46iJU0x7Meta?.name ?? "stores-store_id-sales-sale_id-edit___id",
    path: edit0q46iJU0x7Meta?.path ?? "/stores/:store_id()/sales/:sale_id()/edit",
    meta: edit0q46iJU0x7Meta || {},
    alias: edit0q46iJU0x7Meta?.alias || [],
    redirect: edit0q46iJU0x7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit0q46iJU0x7Meta?.name ?? "stores-store_id-sales-sale_id-edit___en",
    path: edit0q46iJU0x7Meta?.path ?? "/en/stores/:store_id()/sales/:sale_id()/edit",
    meta: edit0q46iJU0x7Meta || {},
    alias: edit0q46iJU0x7Meta?.alias || [],
    redirect: edit0q46iJU0x7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexhVUL7G2m13Meta?.name ?? "stores-store_id-sales-sale_id___id",
    path: indexhVUL7G2m13Meta?.path ?? "/stores/:store_id()/sales/:sale_id()",
    meta: indexhVUL7G2m13Meta || {},
    alias: indexhVUL7G2m13Meta?.alias || [],
    redirect: indexhVUL7G2m13Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexhVUL7G2m13Meta?.name ?? "stores-store_id-sales-sale_id___en",
    path: indexhVUL7G2m13Meta?.path ?? "/en/stores/:store_id()/sales/:sale_id()",
    meta: indexhVUL7G2m13Meta || {},
    alias: indexhVUL7G2m13Meta?.alias || [],
    redirect: indexhVUL7G2m13Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/index.vue").then(m => m.default || m)
  },
  {
    name: editCVQzmT69IqMeta?.name ?? "stores-store_id-sales-sale_id-returns-sale_return_id-edit___id",
    path: editCVQzmT69IqMeta?.path ?? "/stores/:store_id()/sales/:sale_id()/returns/:sale_return_id()/edit",
    meta: editCVQzmT69IqMeta || {},
    alias: editCVQzmT69IqMeta?.alias || [],
    redirect: editCVQzmT69IqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/returns/[sale_return_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editCVQzmT69IqMeta?.name ?? "stores-store_id-sales-sale_id-returns-sale_return_id-edit___en",
    path: editCVQzmT69IqMeta?.path ?? "/en/stores/:store_id()/sales/:sale_id()/returns/:sale_return_id()/edit",
    meta: editCVQzmT69IqMeta || {},
    alias: editCVQzmT69IqMeta?.alias || [],
    redirect: editCVQzmT69IqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/returns/[sale_return_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexy8Zlv4GlZXMeta?.name ?? "stores-store_id-sales-sale_id-returns-sale_return_id___id",
    path: indexy8Zlv4GlZXMeta?.path ?? "/stores/:store_id()/sales/:sale_id()/returns/:sale_return_id()",
    meta: indexy8Zlv4GlZXMeta || {},
    alias: indexy8Zlv4GlZXMeta?.alias || [],
    redirect: indexy8Zlv4GlZXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/returns/[sale_return_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexy8Zlv4GlZXMeta?.name ?? "stores-store_id-sales-sale_id-returns-sale_return_id___en",
    path: indexy8Zlv4GlZXMeta?.path ?? "/en/stores/:store_id()/sales/:sale_id()/returns/:sale_return_id()",
    meta: indexy8Zlv4GlZXMeta || {},
    alias: indexy8Zlv4GlZXMeta?.alias || [],
    redirect: indexy8Zlv4GlZXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/returns/[sale_return_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createn4nHR22mxBMeta?.name ?? "stores-store_id-sales-sale_id-returns-create___id",
    path: createn4nHR22mxBMeta?.path ?? "/stores/:store_id()/sales/:sale_id()/returns/create",
    meta: createn4nHR22mxBMeta || {},
    alias: createn4nHR22mxBMeta?.alias || [],
    redirect: createn4nHR22mxBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/returns/create.vue").then(m => m.default || m)
  },
  {
    name: createn4nHR22mxBMeta?.name ?? "stores-store_id-sales-sale_id-returns-create___en",
    path: createn4nHR22mxBMeta?.path ?? "/en/stores/:store_id()/sales/:sale_id()/returns/create",
    meta: createn4nHR22mxBMeta || {},
    alias: createn4nHR22mxBMeta?.alias || [],
    redirect: createn4nHR22mxBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/[sale_id]/returns/create.vue").then(m => m.default || m)
  },
  {
    name: create68Ym12Bia2Meta?.name ?? "stores-store_id-sales-create___id",
    path: create68Ym12Bia2Meta?.path ?? "/stores/:store_id()/sales/create",
    meta: create68Ym12Bia2Meta || {},
    alias: create68Ym12Bia2Meta?.alias || [],
    redirect: create68Ym12Bia2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/create.vue").then(m => m.default || m)
  },
  {
    name: create68Ym12Bia2Meta?.name ?? "stores-store_id-sales-create___en",
    path: create68Ym12Bia2Meta?.path ?? "/en/stores/:store_id()/sales/create",
    meta: create68Ym12Bia2Meta || {},
    alias: create68Ym12Bia2Meta?.alias || [],
    redirect: create68Ym12Bia2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/create.vue").then(m => m.default || m)
  },
  {
    name: index7wyMeuiVoHMeta?.name ?? "stores-store_id-sales___id",
    path: index7wyMeuiVoHMeta?.path ?? "/stores/:store_id()/sales",
    meta: index7wyMeuiVoHMeta || {},
    alias: index7wyMeuiVoHMeta?.alias || [],
    redirect: index7wyMeuiVoHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/index.vue").then(m => m.default || m)
  },
  {
    name: index7wyMeuiVoHMeta?.name ?? "stores-store_id-sales___en",
    path: index7wyMeuiVoHMeta?.path ?? "/en/stores/:store_id()/sales",
    meta: index7wyMeuiVoHMeta || {},
    alias: index7wyMeuiVoHMeta?.alias || [],
    redirect: index7wyMeuiVoHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/sales/index.vue").then(m => m.default || m)
  },
  {
    name: editL42ZmBECDjMeta?.name ?? "stores-store_id-salespersons-salesperson_id-edit___id",
    path: editL42ZmBECDjMeta?.path ?? "/stores/:store_id()/salespersons/:salesperson_id()/edit",
    meta: editL42ZmBECDjMeta || {},
    alias: editL42ZmBECDjMeta?.alias || [],
    redirect: editL42ZmBECDjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/[salesperson_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editL42ZmBECDjMeta?.name ?? "stores-store_id-salespersons-salesperson_id-edit___en",
    path: editL42ZmBECDjMeta?.path ?? "/en/stores/:store_id()/salespersons/:salesperson_id()/edit",
    meta: editL42ZmBECDjMeta || {},
    alias: editL42ZmBECDjMeta?.alias || [],
    redirect: editL42ZmBECDjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/[salesperson_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexAm9sjjowvyMeta?.name ?? "stores-store_id-salespersons-salesperson_id___id",
    path: indexAm9sjjowvyMeta?.path ?? "/stores/:store_id()/salespersons/:salesperson_id()",
    meta: indexAm9sjjowvyMeta || {},
    alias: indexAm9sjjowvyMeta?.alias || [],
    redirect: indexAm9sjjowvyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/[salesperson_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAm9sjjowvyMeta?.name ?? "stores-store_id-salespersons-salesperson_id___en",
    path: indexAm9sjjowvyMeta?.path ?? "/en/stores/:store_id()/salespersons/:salesperson_id()",
    meta: indexAm9sjjowvyMeta || {},
    alias: indexAm9sjjowvyMeta?.alias || [],
    redirect: indexAm9sjjowvyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/[salesperson_id]/index.vue").then(m => m.default || m)
  },
  {
    name: create1Juh5JiSemMeta?.name ?? "stores-store_id-salespersons-create___id",
    path: create1Juh5JiSemMeta?.path ?? "/stores/:store_id()/salespersons/create",
    meta: create1Juh5JiSemMeta || {},
    alias: create1Juh5JiSemMeta?.alias || [],
    redirect: create1Juh5JiSemMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/create.vue").then(m => m.default || m)
  },
  {
    name: create1Juh5JiSemMeta?.name ?? "stores-store_id-salespersons-create___en",
    path: create1Juh5JiSemMeta?.path ?? "/en/stores/:store_id()/salespersons/create",
    meta: create1Juh5JiSemMeta || {},
    alias: create1Juh5JiSemMeta?.alias || [],
    redirect: create1Juh5JiSemMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/create.vue").then(m => m.default || m)
  },
  {
    name: indexOwbSgwLl1XMeta?.name ?? "stores-store_id-salespersons___id",
    path: indexOwbSgwLl1XMeta?.path ?? "/stores/:store_id()/salespersons",
    meta: indexOwbSgwLl1XMeta || {},
    alias: indexOwbSgwLl1XMeta?.alias || [],
    redirect: indexOwbSgwLl1XMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/index.vue").then(m => m.default || m)
  },
  {
    name: indexOwbSgwLl1XMeta?.name ?? "stores-store_id-salespersons___en",
    path: indexOwbSgwLl1XMeta?.path ?? "/en/stores/:store_id()/salespersons",
    meta: indexOwbSgwLl1XMeta || {},
    alias: indexOwbSgwLl1XMeta?.alias || [],
    redirect: indexOwbSgwLl1XMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/salespersons/index.vue").then(m => m.default || m)
  },
  {
    name: editdHRfrqVcWoMeta?.name ?? "stores-store_id-service-types-service_type_id-edit___id",
    path: editdHRfrqVcWoMeta?.path ?? "/stores/:store_id()/service-types/:service_type_id()/edit",
    meta: editdHRfrqVcWoMeta || {},
    alias: editdHRfrqVcWoMeta?.alias || [],
    redirect: editdHRfrqVcWoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/[service_type_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editdHRfrqVcWoMeta?.name ?? "stores-store_id-service-types-service_type_id-edit___en",
    path: editdHRfrqVcWoMeta?.path ?? "/en/stores/:store_id()/service-types/:service_type_id()/edit",
    meta: editdHRfrqVcWoMeta || {},
    alias: editdHRfrqVcWoMeta?.alias || [],
    redirect: editdHRfrqVcWoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/[service_type_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexy0QIZwAsGSMeta?.name ?? "stores-store_id-service-types-service_type_id___id",
    path: indexy0QIZwAsGSMeta?.path ?? "/stores/:store_id()/service-types/:service_type_id()",
    meta: indexy0QIZwAsGSMeta || {},
    alias: indexy0QIZwAsGSMeta?.alias || [],
    redirect: indexy0QIZwAsGSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/[service_type_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexy0QIZwAsGSMeta?.name ?? "stores-store_id-service-types-service_type_id___en",
    path: indexy0QIZwAsGSMeta?.path ?? "/en/stores/:store_id()/service-types/:service_type_id()",
    meta: indexy0QIZwAsGSMeta || {},
    alias: indexy0QIZwAsGSMeta?.alias || [],
    redirect: indexy0QIZwAsGSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/[service_type_id]/index.vue").then(m => m.default || m)
  },
  {
    name: create8CF4pLevE0Meta?.name ?? "stores-store_id-service-types-create___id",
    path: create8CF4pLevE0Meta?.path ?? "/stores/:store_id()/service-types/create",
    meta: create8CF4pLevE0Meta || {},
    alias: create8CF4pLevE0Meta?.alias || [],
    redirect: create8CF4pLevE0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/create.vue").then(m => m.default || m)
  },
  {
    name: create8CF4pLevE0Meta?.name ?? "stores-store_id-service-types-create___en",
    path: create8CF4pLevE0Meta?.path ?? "/en/stores/:store_id()/service-types/create",
    meta: create8CF4pLevE0Meta || {},
    alias: create8CF4pLevE0Meta?.alias || [],
    redirect: create8CF4pLevE0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/create.vue").then(m => m.default || m)
  },
  {
    name: index8PbEwxmXknMeta?.name ?? "stores-store_id-service-types___id",
    path: index8PbEwxmXknMeta?.path ?? "/stores/:store_id()/service-types",
    meta: index8PbEwxmXknMeta || {},
    alias: index8PbEwxmXknMeta?.alias || [],
    redirect: index8PbEwxmXknMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/index.vue").then(m => m.default || m)
  },
  {
    name: index8PbEwxmXknMeta?.name ?? "stores-store_id-service-types___en",
    path: index8PbEwxmXknMeta?.path ?? "/en/stores/:store_id()/service-types",
    meta: index8PbEwxmXknMeta || {},
    alias: index8PbEwxmXknMeta?.alias || [],
    redirect: index8PbEwxmXknMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/service-types/index.vue").then(m => m.default || m)
  },
  {
    name: showcasePHdBfK5rEgMeta?.name ?? "stores-store_id-showcase___id",
    path: showcasePHdBfK5rEgMeta?.path ?? "/stores/:store_id()/showcase",
    meta: showcasePHdBfK5rEgMeta || {},
    alias: showcasePHdBfK5rEgMeta?.alias || [],
    redirect: showcasePHdBfK5rEgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/showcase.vue").then(m => m.default || m)
  },
  {
    name: showcasePHdBfK5rEgMeta?.name ?? "stores-store_id-showcase___en",
    path: showcasePHdBfK5rEgMeta?.path ?? "/en/stores/:store_id()/showcase",
    meta: showcasePHdBfK5rEgMeta || {},
    alias: showcasePHdBfK5rEgMeta?.alias || [],
    redirect: showcasePHdBfK5rEgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/showcase.vue").then(m => m.default || m)
  },
  {
    name: editcwlV6eeQGGMeta?.name ?? "stores-store_id-suppliers-supplier_id-edit___id",
    path: editcwlV6eeQGGMeta?.path ?? "/stores/:store_id()/suppliers/:supplier_id()/edit",
    meta: editcwlV6eeQGGMeta || {},
    alias: editcwlV6eeQGGMeta?.alias || [],
    redirect: editcwlV6eeQGGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/[supplier_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editcwlV6eeQGGMeta?.name ?? "stores-store_id-suppliers-supplier_id-edit___en",
    path: editcwlV6eeQGGMeta?.path ?? "/en/stores/:store_id()/suppliers/:supplier_id()/edit",
    meta: editcwlV6eeQGGMeta || {},
    alias: editcwlV6eeQGGMeta?.alias || [],
    redirect: editcwlV6eeQGGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/[supplier_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index3Q0QPHC6xIMeta?.name ?? "stores-store_id-suppliers-supplier_id___id",
    path: index3Q0QPHC6xIMeta?.path ?? "/stores/:store_id()/suppliers/:supplier_id()",
    meta: index3Q0QPHC6xIMeta || {},
    alias: index3Q0QPHC6xIMeta?.alias || [],
    redirect: index3Q0QPHC6xIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/[supplier_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index3Q0QPHC6xIMeta?.name ?? "stores-store_id-suppliers-supplier_id___en",
    path: index3Q0QPHC6xIMeta?.path ?? "/en/stores/:store_id()/suppliers/:supplier_id()",
    meta: index3Q0QPHC6xIMeta || {},
    alias: index3Q0QPHC6xIMeta?.alias || [],
    redirect: index3Q0QPHC6xIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/[supplier_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createjzDPbqgtkWMeta?.name ?? "stores-store_id-suppliers-create___id",
    path: createjzDPbqgtkWMeta?.path ?? "/stores/:store_id()/suppliers/create",
    meta: createjzDPbqgtkWMeta || {},
    alias: createjzDPbqgtkWMeta?.alias || [],
    redirect: createjzDPbqgtkWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/create.vue").then(m => m.default || m)
  },
  {
    name: createjzDPbqgtkWMeta?.name ?? "stores-store_id-suppliers-create___en",
    path: createjzDPbqgtkWMeta?.path ?? "/en/stores/:store_id()/suppliers/create",
    meta: createjzDPbqgtkWMeta || {},
    alias: createjzDPbqgtkWMeta?.alias || [],
    redirect: createjzDPbqgtkWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/create.vue").then(m => m.default || m)
  },
  {
    name: indexfMUMDsxiN4Meta?.name ?? "stores-store_id-suppliers___id",
    path: indexfMUMDsxiN4Meta?.path ?? "/stores/:store_id()/suppliers",
    meta: indexfMUMDsxiN4Meta || {},
    alias: indexfMUMDsxiN4Meta?.alias || [],
    redirect: indexfMUMDsxiN4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/index.vue").then(m => m.default || m)
  },
  {
    name: indexfMUMDsxiN4Meta?.name ?? "stores-store_id-suppliers___en",
    path: indexfMUMDsxiN4Meta?.path ?? "/en/stores/:store_id()/suppliers",
    meta: indexfMUMDsxiN4Meta || {},
    alias: indexfMUMDsxiN4Meta?.alias || [],
    redirect: indexfMUMDsxiN4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/suppliers/index.vue").then(m => m.default || m)
  },
  {
    name: editd7NMyrhFhPMeta?.name ?? "stores-store_id-units-unit_id-edit___id",
    path: editd7NMyrhFhPMeta?.path ?? "/stores/:store_id()/units/:unit_id()/edit",
    meta: editd7NMyrhFhPMeta || {},
    alias: editd7NMyrhFhPMeta?.alias || [],
    redirect: editd7NMyrhFhPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/[unit_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editd7NMyrhFhPMeta?.name ?? "stores-store_id-units-unit_id-edit___en",
    path: editd7NMyrhFhPMeta?.path ?? "/en/stores/:store_id()/units/:unit_id()/edit",
    meta: editd7NMyrhFhPMeta || {},
    alias: editd7NMyrhFhPMeta?.alias || [],
    redirect: editd7NMyrhFhPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/[unit_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexlLmtwQh3u1Meta?.name ?? "stores-store_id-units-unit_id___id",
    path: indexlLmtwQh3u1Meta?.path ?? "/stores/:store_id()/units/:unit_id()",
    meta: indexlLmtwQh3u1Meta || {},
    alias: indexlLmtwQh3u1Meta?.alias || [],
    redirect: indexlLmtwQh3u1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/[unit_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlLmtwQh3u1Meta?.name ?? "stores-store_id-units-unit_id___en",
    path: indexlLmtwQh3u1Meta?.path ?? "/en/stores/:store_id()/units/:unit_id()",
    meta: indexlLmtwQh3u1Meta || {},
    alias: indexlLmtwQh3u1Meta?.alias || [],
    redirect: indexlLmtwQh3u1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/[unit_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createziCwl6kH40Meta?.name ?? "stores-store_id-units-create___id",
    path: createziCwl6kH40Meta?.path ?? "/stores/:store_id()/units/create",
    meta: createziCwl6kH40Meta || {},
    alias: createziCwl6kH40Meta?.alias || [],
    redirect: createziCwl6kH40Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/create.vue").then(m => m.default || m)
  },
  {
    name: createziCwl6kH40Meta?.name ?? "stores-store_id-units-create___en",
    path: createziCwl6kH40Meta?.path ?? "/en/stores/:store_id()/units/create",
    meta: createziCwl6kH40Meta || {},
    alias: createziCwl6kH40Meta?.alias || [],
    redirect: createziCwl6kH40Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/create.vue").then(m => m.default || m)
  },
  {
    name: indexdg4MrA6cjFMeta?.name ?? "stores-store_id-units___id",
    path: indexdg4MrA6cjFMeta?.path ?? "/stores/:store_id()/units",
    meta: indexdg4MrA6cjFMeta || {},
    alias: indexdg4MrA6cjFMeta?.alias || [],
    redirect: indexdg4MrA6cjFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/index.vue").then(m => m.default || m)
  },
  {
    name: indexdg4MrA6cjFMeta?.name ?? "stores-store_id-units___en",
    path: indexdg4MrA6cjFMeta?.path ?? "/en/stores/:store_id()/units",
    meta: indexdg4MrA6cjFMeta || {},
    alias: indexdg4MrA6cjFMeta?.alias || [],
    redirect: indexdg4MrA6cjFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/stores/[store_id]/units/index.vue").then(m => m.default || m)
  },
  {
    name: index1mi44Cd7YfMeta?.name ?? "warehouses-warehouse_id-admin-receivings-admin_receiving_id___id",
    path: index1mi44Cd7YfMeta?.path ?? "/warehouses/:warehouse_id()/admin-receivings/:admin_receiving_id()",
    meta: index1mi44Cd7YfMeta || {},
    alias: index1mi44Cd7YfMeta?.alias || [],
    redirect: index1mi44Cd7YfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/admin-receivings/[admin_receiving_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1mi44Cd7YfMeta?.name ?? "warehouses-warehouse_id-admin-receivings-admin_receiving_id___en",
    path: index1mi44Cd7YfMeta?.path ?? "/en/warehouses/:warehouse_id()/admin-receivings/:admin_receiving_id()",
    meta: index1mi44Cd7YfMeta || {},
    alias: index1mi44Cd7YfMeta?.alias || [],
    redirect: index1mi44Cd7YfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/admin-receivings/[admin_receiving_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJrCxZgoHVOMeta?.name ?? "warehouses-warehouse_id-admin-receivings___id",
    path: indexJrCxZgoHVOMeta?.path ?? "/warehouses/:warehouse_id()/admin-receivings",
    meta: indexJrCxZgoHVOMeta || {},
    alias: indexJrCxZgoHVOMeta?.alias || [],
    redirect: indexJrCxZgoHVOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/admin-receivings/index.vue").then(m => m.default || m)
  },
  {
    name: indexJrCxZgoHVOMeta?.name ?? "warehouses-warehouse_id-admin-receivings___en",
    path: indexJrCxZgoHVOMeta?.path ?? "/en/warehouses/:warehouse_id()/admin-receivings",
    meta: indexJrCxZgoHVOMeta || {},
    alias: indexJrCxZgoHVOMeta?.alias || [],
    redirect: indexJrCxZgoHVOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/admin-receivings/index.vue").then(m => m.default || m)
  },
  {
    name: indexOM7H5xXufFMeta?.name ?? "warehouses-warehouse_id-dashboard___id",
    path: indexOM7H5xXufFMeta?.path ?? "/warehouses/:warehouse_id()/dashboard",
    meta: indexOM7H5xXufFMeta || {},
    alias: indexOM7H5xXufFMeta?.alias || [],
    redirect: indexOM7H5xXufFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexOM7H5xXufFMeta?.name ?? "warehouses-warehouse_id-dashboard___en",
    path: indexOM7H5xXufFMeta?.path ?? "/en/warehouses/:warehouse_id()/dashboard",
    meta: indexOM7H5xXufFMeta || {},
    alias: indexOM7H5xXufFMeta?.alias || [],
    redirect: indexOM7H5xXufFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: index8J05EUVxvXMeta?.name ?? "warehouses-warehouse_id-item-deliveries-item_delivery_id___id",
    path: index8J05EUVxvXMeta?.path ?? "/warehouses/:warehouse_id()/item-deliveries/:item_delivery_id()",
    meta: index8J05EUVxvXMeta || {},
    alias: index8J05EUVxvXMeta?.alias || [],
    redirect: index8J05EUVxvXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/item-deliveries/[item_delivery_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index8J05EUVxvXMeta?.name ?? "warehouses-warehouse_id-item-deliveries-item_delivery_id___en",
    path: index8J05EUVxvXMeta?.path ?? "/en/warehouses/:warehouse_id()/item-deliveries/:item_delivery_id()",
    meta: index8J05EUVxvXMeta || {},
    alias: index8J05EUVxvXMeta?.alias || [],
    redirect: index8J05EUVxvXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/item-deliveries/[item_delivery_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexX2mOGtp8FsMeta?.name ?? "warehouses-warehouse_id-item-deliveries___id",
    path: indexX2mOGtp8FsMeta?.path ?? "/warehouses/:warehouse_id()/item-deliveries",
    meta: indexX2mOGtp8FsMeta || {},
    alias: indexX2mOGtp8FsMeta?.alias || [],
    redirect: indexX2mOGtp8FsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/item-deliveries/index.vue").then(m => m.default || m)
  },
  {
    name: indexX2mOGtp8FsMeta?.name ?? "warehouses-warehouse_id-item-deliveries___en",
    path: indexX2mOGtp8FsMeta?.path ?? "/en/warehouses/:warehouse_id()/item-deliveries",
    meta: indexX2mOGtp8FsMeta || {},
    alias: indexX2mOGtp8FsMeta?.alias || [],
    redirect: indexX2mOGtp8FsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/item-deliveries/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSpz6CUsZHMeta?.name ?? "warehouses-warehouse_id-stock-adjustments-stock_adjustment_id___id",
    path: indexgSpz6CUsZHMeta?.path ?? "/warehouses/:warehouse_id()/stock-adjustments/:stock_adjustment_id()",
    meta: indexgSpz6CUsZHMeta || {},
    alias: indexgSpz6CUsZHMeta?.alias || [],
    redirect: indexgSpz6CUsZHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-adjustments/[stock_adjustment_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSpz6CUsZHMeta?.name ?? "warehouses-warehouse_id-stock-adjustments-stock_adjustment_id___en",
    path: indexgSpz6CUsZHMeta?.path ?? "/en/warehouses/:warehouse_id()/stock-adjustments/:stock_adjustment_id()",
    meta: indexgSpz6CUsZHMeta || {},
    alias: indexgSpz6CUsZHMeta?.alias || [],
    redirect: indexgSpz6CUsZHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-adjustments/[stock_adjustment_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createaDW3vfr2dxMeta?.name ?? "warehouses-warehouse_id-stock-adjustments-create___id",
    path: createaDW3vfr2dxMeta?.path ?? "/warehouses/:warehouse_id()/stock-adjustments/create",
    meta: createaDW3vfr2dxMeta || {},
    alias: createaDW3vfr2dxMeta?.alias || [],
    redirect: createaDW3vfr2dxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-adjustments/create.vue").then(m => m.default || m)
  },
  {
    name: createaDW3vfr2dxMeta?.name ?? "warehouses-warehouse_id-stock-adjustments-create___en",
    path: createaDW3vfr2dxMeta?.path ?? "/en/warehouses/:warehouse_id()/stock-adjustments/create",
    meta: createaDW3vfr2dxMeta || {},
    alias: createaDW3vfr2dxMeta?.alias || [],
    redirect: createaDW3vfr2dxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-adjustments/create.vue").then(m => m.default || m)
  },
  {
    name: indexhO0bQnLUmBMeta?.name ?? "warehouses-warehouse_id-stock-adjustments___id",
    path: indexhO0bQnLUmBMeta?.path ?? "/warehouses/:warehouse_id()/stock-adjustments",
    meta: indexhO0bQnLUmBMeta || {},
    alias: indexhO0bQnLUmBMeta?.alias || [],
    redirect: indexhO0bQnLUmBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-adjustments/index.vue").then(m => m.default || m)
  },
  {
    name: indexhO0bQnLUmBMeta?.name ?? "warehouses-warehouse_id-stock-adjustments___en",
    path: indexhO0bQnLUmBMeta?.path ?? "/en/warehouses/:warehouse_id()/stock-adjustments",
    meta: indexhO0bQnLUmBMeta || {},
    alias: indexhO0bQnLUmBMeta?.alias || [],
    redirect: indexhO0bQnLUmBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-adjustments/index.vue").then(m => m.default || m)
  },
  {
    name: indexyuuAk071WcMeta?.name ?? "warehouses-warehouse_id-stock-transfers-stock_transfer_id___id",
    path: indexyuuAk071WcMeta?.path ?? "/warehouses/:warehouse_id()/stock-transfers/:stock_transfer_id()",
    meta: indexyuuAk071WcMeta || {},
    alias: indexyuuAk071WcMeta?.alias || [],
    redirect: indexyuuAk071WcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-transfers/[stock_transfer_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyuuAk071WcMeta?.name ?? "warehouses-warehouse_id-stock-transfers-stock_transfer_id___en",
    path: indexyuuAk071WcMeta?.path ?? "/en/warehouses/:warehouse_id()/stock-transfers/:stock_transfer_id()",
    meta: indexyuuAk071WcMeta || {},
    alias: indexyuuAk071WcMeta?.alias || [],
    redirect: indexyuuAk071WcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-transfers/[stock_transfer_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createa8gqkuyMq3Meta?.name ?? "warehouses-warehouse_id-stock-transfers-create___id",
    path: createa8gqkuyMq3Meta?.path ?? "/warehouses/:warehouse_id()/stock-transfers/create",
    meta: createa8gqkuyMq3Meta || {},
    alias: createa8gqkuyMq3Meta?.alias || [],
    redirect: createa8gqkuyMq3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-transfers/create.vue").then(m => m.default || m)
  },
  {
    name: createa8gqkuyMq3Meta?.name ?? "warehouses-warehouse_id-stock-transfers-create___en",
    path: createa8gqkuyMq3Meta?.path ?? "/en/warehouses/:warehouse_id()/stock-transfers/create",
    meta: createa8gqkuyMq3Meta || {},
    alias: createa8gqkuyMq3Meta?.alias || [],
    redirect: createa8gqkuyMq3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-transfers/create.vue").then(m => m.default || m)
  },
  {
    name: indexWmdeJjxu9zMeta?.name ?? "warehouses-warehouse_id-stock-transfers___id",
    path: indexWmdeJjxu9zMeta?.path ?? "/warehouses/:warehouse_id()/stock-transfers",
    meta: indexWmdeJjxu9zMeta || {},
    alias: indexWmdeJjxu9zMeta?.alias || [],
    redirect: indexWmdeJjxu9zMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-transfers/index.vue").then(m => m.default || m)
  },
  {
    name: indexWmdeJjxu9zMeta?.name ?? "warehouses-warehouse_id-stock-transfers___en",
    path: indexWmdeJjxu9zMeta?.path ?? "/en/warehouses/:warehouse_id()/stock-transfers",
    meta: indexWmdeJjxu9zMeta || {},
    alias: indexWmdeJjxu9zMeta?.alias || [],
    redirect: indexWmdeJjxu9zMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/stock-transfers/index.vue").then(m => m.default || m)
  },
  {
    name: editTBvOEhhs8fMeta?.name ?? "warehouses-warehouse_id-warehouse-receivings-warehouse_receiving_id-edit___id",
    path: editTBvOEhhs8fMeta?.path ?? "/warehouses/:warehouse_id()/warehouse-receivings/:warehouse_receiving_id()/edit",
    meta: editTBvOEhhs8fMeta || {},
    alias: editTBvOEhhs8fMeta?.alias || [],
    redirect: editTBvOEhhs8fMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/[warehouse_receiving_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editTBvOEhhs8fMeta?.name ?? "warehouses-warehouse_id-warehouse-receivings-warehouse_receiving_id-edit___en",
    path: editTBvOEhhs8fMeta?.path ?? "/en/warehouses/:warehouse_id()/warehouse-receivings/:warehouse_receiving_id()/edit",
    meta: editTBvOEhhs8fMeta || {},
    alias: editTBvOEhhs8fMeta?.alias || [],
    redirect: editTBvOEhhs8fMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/[warehouse_receiving_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexY7Vj49Rr5HMeta?.name ?? "warehouses-warehouse_id-warehouse-receivings-warehouse_receiving_id___id",
    path: indexY7Vj49Rr5HMeta?.path ?? "/warehouses/:warehouse_id()/warehouse-receivings/:warehouse_receiving_id()",
    meta: indexY7Vj49Rr5HMeta || {},
    alias: indexY7Vj49Rr5HMeta?.alias || [],
    redirect: indexY7Vj49Rr5HMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/[warehouse_receiving_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexY7Vj49Rr5HMeta?.name ?? "warehouses-warehouse_id-warehouse-receivings-warehouse_receiving_id___en",
    path: indexY7Vj49Rr5HMeta?.path ?? "/en/warehouses/:warehouse_id()/warehouse-receivings/:warehouse_receiving_id()",
    meta: indexY7Vj49Rr5HMeta || {},
    alias: indexY7Vj49Rr5HMeta?.alias || [],
    redirect: indexY7Vj49Rr5HMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/[warehouse_receiving_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createEyik0Z0wEWMeta?.name ?? "warehouses-warehouse_id-warehouse-receivings-create___id",
    path: createEyik0Z0wEWMeta?.path ?? "/warehouses/:warehouse_id()/warehouse-receivings/create",
    meta: createEyik0Z0wEWMeta || {},
    alias: createEyik0Z0wEWMeta?.alias || [],
    redirect: createEyik0Z0wEWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/create.vue").then(m => m.default || m)
  },
  {
    name: createEyik0Z0wEWMeta?.name ?? "warehouses-warehouse_id-warehouse-receivings-create___en",
    path: createEyik0Z0wEWMeta?.path ?? "/en/warehouses/:warehouse_id()/warehouse-receivings/create",
    meta: createEyik0Z0wEWMeta || {},
    alias: createEyik0Z0wEWMeta?.alias || [],
    redirect: createEyik0Z0wEWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/create.vue").then(m => m.default || m)
  },
  {
    name: indexhAGNclQfwtMeta?.name ?? "warehouses-warehouse_id-warehouse-receivings___id",
    path: indexhAGNclQfwtMeta?.path ?? "/warehouses/:warehouse_id()/warehouse-receivings",
    meta: indexhAGNclQfwtMeta || {},
    alias: indexhAGNclQfwtMeta?.alias || [],
    redirect: indexhAGNclQfwtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/index.vue").then(m => m.default || m)
  },
  {
    name: indexhAGNclQfwtMeta?.name ?? "warehouses-warehouse_id-warehouse-receivings___en",
    path: indexhAGNclQfwtMeta?.path ?? "/en/warehouses/:warehouse_id()/warehouse-receivings",
    meta: indexhAGNclQfwtMeta || {},
    alias: indexhAGNclQfwtMeta?.alias || [],
    redirect: indexhAGNclQfwtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pos-fe/pos-fe/pages/warehouses/[warehouse_id]/warehouse-receivings/index.vue").then(m => m.default || m)
  }
]