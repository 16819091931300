import { permissionView } from "~/constants/permission";

function extractModuleName(input: string): string {
  const path = input.replaceAll('-', ' ').split('/')
  let activeMenu = path[1]
  let translation = 0
  // All menu
  const allMenu = ['companies', 'warehouses', 'stores']
  //check translation
  if (allMenu.indexOf(path[1]) === -1) {
    translation = 1
    activeMenu = path[2]
  }

  if (activeMenu == 'companies') {
    return path[2 + translation] ?? ''
  } else {
    if (path[3 + translation] == 'reports') {
      if (path[4 + translation] == 'sales') return 'sale reports'
      if (path[4 + translation] == 'purchases') return 'purchase reports'
      if (path[4 + translation] == 'stock alerts') return 'stock alerts'
    }
    return path[3 + translation] ?? ''
  }
}

export default defineNuxtRouteMiddleware((to, from) => {
  const nuxt = useNuxtApp()

  // Check if user has the access to the targeted route
  const moduleName = extractModuleName(to.path!.toString())

  // Excluded urls
  const excludedRouteNames = ['dashboard', '']

  if (excludedRouteNames.indexOf(moduleName) === -1 && !nuxt.$hasAccess(`${permissionView} - ${moduleName}`)) {
    nuxt.$notify(false, nuxt.$getTranslation('shared.notifications.unauthorized'))
    return navigateTo('/')
    // return abortNavigation()
  }
})