import revive_payload_client_4sVQNw7RlN from "/home/runner/work/pos-fe/pos-fe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/runner/work/pos-fe/pos-fe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/pos-fe/pos-fe/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/pos-fe/pos-fe/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/runner/work/pos-fe/pos-fe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/pos-fe/pos-fe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/home/runner/work/pos-fe/pos-fe/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/home/runner/work/pos-fe/pos-fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/runner/work/pos-fe/pos-fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import quasar_plugin_G9rpXFQbul from "/home/runner/work/pos-fe/pos-fe/.nuxt/quasar-plugin.mjs";
import provide_4gUmUdUSZU from "/home/runner/work/pos-fe/pos-fe/node_modules/nuxt-quasar-ui/dist/runtime/provide.mjs";
import templates_pwa_client_56e83239_SUFWDbTCiD from "/home/runner/work/pos-fe/pos-fe/.nuxt/templates.pwa.client.56e83239.ts";
import plugin_client_i8AMfKeYnY from "/home/runner/work/pos-fe/pos-fe/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/home/runner/work/pos-fe/pos-fe/.nuxt/unocss.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/pos-fe/pos-fe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import timeInterval_H9OKfTNmGV from "/home/runner/work/pos-fe/pos-fe/plugins/timeInterval.ts";
import db_mWfD51wL77 from "/home/runner/work/pos-fe/pos-fe/plugins/db.ts";
import fetch_14DdzPiXUB from "/home/runner/work/pos-fe/pos-fe/plugins/fetch.ts";
import form_x1SIvg4SAv from "/home/runner/work/pos-fe/pos-fe/plugins/form.ts";
import notification_h17XTKlCK5 from "/home/runner/work/pos-fe/pos-fe/plugins/notification.ts";
import permission_RYX9gEepuf from "/home/runner/work/pos-fe/pos-fe/plugins/permission.ts";
import route_cvS2qIkLzw from "/home/runner/work/pos-fe/pos-fe/plugins/route.ts";
import sentry_3AyO8nEfhE from "/home/runner/work/pos-fe/pos-fe/plugins/sentry.ts";
import string_1zTijVNykV from "/home/runner/work/pos-fe/pos-fe/plugins/string.ts";
import svg_Uew0JGO3gG from "/home/runner/work/pos-fe/pos-fe/plugins/svg.ts";
import translate_56zPzW3R38 from "/home/runner/work/pos-fe/pos-fe/plugins/translate.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  quasar_plugin_G9rpXFQbul,
  provide_4gUmUdUSZU,
  templates_pwa_client_56e83239_SUFWDbTCiD,
  plugin_client_i8AMfKeYnY,
  unocss_MzCDxu9LMj,
  chunk_reload_client_UciE0i6zes,
  timeInterval_H9OKfTNmGV,
  db_mWfD51wL77,
  fetch_14DdzPiXUB,
  form_x1SIvg4SAv,
  notification_h17XTKlCK5,
  permission_RYX9gEepuf,
  route_cvS2qIkLzw,
  sentry_3AyO8nEfhE,
  string_1zTijVNykV,
  svg_Uew0JGO3gG,
  translate_56zPzW3R38
]